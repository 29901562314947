<template>
  <div class="relative inline-block w-full max-w-xs" ref="container">
    <label :for="id" class="text-sm font-medium block mb-1 truncate w-full">{{
      label
    }}</label>
    <div class="relative">
      <button
        class="w-full px-1 py-2 border border-gray-300 rounded-md bg-white shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 overflow-hidden text-ellipsis whitespace-nowrap"
        @click="toggleOptions"
        :aria-expanded="showOptions"
        :aria-controls="id + '-options'"
        ref="buttonRef"
        :style="{ width: buttonWidth }"
      >
        {{ selectedOption ? selectedOption.label : placeholder }}
      </button>

      <div
        v-if="showOptions"
        :id="id + '-options'"
        class="absolute z-20 mt-1 mx-1 px-1 border border-gray-300 rounded-md bg-white shadow-lg max-h-64 overflow-y-auto whitespace-nowrap"
        :style="{ width: buttonWidth, top: '100%', left: 0 }"
      >
        <div
          v-for="option in options"
          :key="option.value"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
          :class="{ '': option.value === selectedOption?.value }"
          @click="selectOption(option)"
        >
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  computed,
  defineProps,
  defineEmits,
  onMounted,
  onUnmounted,
} from "vue";

interface Option {
  value: string;
  label: string;
}

const props = defineProps<{
  id: string;
  label: string;
  options: Option[];
  placeholder: string;
  modelValue: Option;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", value: Option): void;
}>();

const showOptions = ref(false);
const selectedOption = ref<Option>(props.modelValue);
const buttonRef = ref<HTMLElement | null>(null);
const container = ref<HTMLElement | null>(null);

const toggleOptions = () => {
  showOptions.value = !showOptions.value;
};

const selectOption = (option: Option) => {
  selectedOption.value = option;
  emit("update:modelValue", option);
  showOptions.value = false;
};

const getScreenWidth = () => window.innerWidth;

const measureTextWidth = (text: string): number => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) return 0;
  context.font = "16px sans-serif";
  return context.measureText(text).width;
};

const buttonWidth = computed(() => {
  if (!buttonRef.value) return "auto";

  const screenWidth = getScreenWidth();
  let adjustment = 0;
  if (screenWidth < 640) {
    adjustment = 150;
  } else if (screenWidth < 768) {
    adjustment = 110;
  } else if (screenWidth < 1024) {
    adjustment = 70;
  } else {
    adjustment = 0;
  }

  const longestOptionWidth = Math.max(
    ...props.options.map((option) => measureTextWidth(option.label))
  );

  return `${
    Math.max(buttonRef.value.offsetWidth, longestOptionWidth) + 30 - adjustment
  }px`;
});

const closeOptions = (event: MouseEvent) => {
  if (container.value && !container.value.contains(event.target as Node)) {
    showOptions.value = false;
  }
};

onMounted(() => {
  document.addEventListener("click", closeOptions);
});

onUnmounted(() => {
  document.removeEventListener("click", closeOptions);
});
</script>
