<template>
  <div class="flex items-center">
    <input
      type="checkbox"
      :id="id"
      v-model="checked"
      :class="[baseClasses, checked ? checkedClasses : uncheckedClasses]"
      @change="updateValue"
    />
    <label :for="id" :class="labelClasses">
      <slot></slot>
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "CheckBox",
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    baseClasses: {
      type: String,
      required: true,
    },
    checkedClasses: {
      type: String,
      required: true,
    },
    uncheckedClasses: {
      type: String,
      required: true,
    },
    labelClasses: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const checked = ref(props.modelValue);

    watch(
      () => props.modelValue,
      (newValue) => {
        checked.value = newValue;
      }
    );

    const updateValue = () => {
      emit("update:modelValue", checked.value);
    };

    return {
      checked,
      updateValue,
    };
  },
});
</script>

<style scoped>
input[type="checkbox"]::before {
  content: "\f00c"; /* Font Awesome checkmark */
  font-family: "Font Awesome 5 Free"; /* Ensure Font Awesome is included */
  font-weight: 900;
  color: white; /* White tick mark */
  font-size: 0.75rem; /* Adjust font size for the tick mark */
  display: none; /* Hide by default */
  position: absolute; /* Absolute positioning for centering */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for perfect centering */
}

input[type="checkbox"]:checked::before {
  display: block; /* Show tick mark when checked */
}
</style>
