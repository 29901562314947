<template>
  <div
    v-if="errorMessage"
    role="alert"
    class="flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
    style="max-height: 80px; overflow-y: auto"
  >
    <!-- Icon Container -->
    <div
      class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
    >
      <!-- Custom Image Icon -->
      <img
        src="../../assets/shared/failure.svg"
        alt="Error Icon"
        class="w-4 h-4"
      />
    </div>
    <p>{{ errorMessage }}</p>
  </div>
  <section class="mt-16 max-w-full max-md:mt-10">
    <div class="flex gap-6 flex-row max-lg:flex-col">
      <div class="flex flex-col max-md:ml-0 max-md:w-full">
        <div
          class="flex flex-col px-6 py-8 mx-auto w-full bg-white rounded border border-gray-300 border-solid max-md:px-5 max-md:mt-4"
        >
          <div class="flex items-center">
            <img
              loading="lazy"
              src="../../assets/onboarding/google-calendar-icon.png"
              alt="Google Calendar logo"
              class="object-contain w-16 h-auto"
            />
            <h2 class="text-xl tracking-wide leading-snug text-gray-700">
              Calendar
            </h2>
          </div>
          <div class="flex flex-col mt-6 w-full">
            <h2
              class="text-xl font-semibold tracking-wide leading-snug text-neutral-900"
            >
              Connect Google Calendar
            </h2>
            <p class="mt-2 text-sm tracking-wide leading-5 text-gray-500">
              Connect Google Calendar and set your availability accordingly
            </p>
          </div>
          <div>
            <button
              class="overflow-hidden self-stretch px-3.5 py-2.5 mt-6 w-full text-sm font-medium leading-none text-white bg-violet-600 rounded-md shadow-sm"
              @click="connectGoogleCalendar"
            >
              Connect now
            </button>
          </div>
        </div>
      </div>

      <div class="flex flex-col max-md:ml-0 max-md:w-full">
        <div
          class="flex flex-col px-6 py-8 mx-auto w-full bg-white rounded border border-gray-300 border-solid max-md:px-5 max-md:mt-4"
        >
          <div class="flex items-center">
            <img
              loading="lazy"
              src="../../assets/onboarding/outlook-calendar-icon.png"
              alt="Google Calendar logo"
              class="object-contain w-12 h-auto"
            />
            <h2 class="text-xl tracking-wide leading-snug text-outlook-blue">
              Outlook
            </h2>
          </div>
          <div class="flex flex-col w-full mt-10">
            <h2
              class="text-xl font-semibold tracking-wide leading-snug text-neutral-900"
            >
              Connect Outlook Calendar
            </h2>
            <p
              class="mt-2 text-sm tracking-wide leading-5 text-gray-500 lg:whitespace-nowrap"
            >
              Connect Outlook Calendar and set your availability accordingly
            </p>
          </div>
          <div>
            <button
              class="overflow-hidden self-stretch px-3.5 py-2.5 mt-6 w-full text-sm font-medium leading-none text-indigo-700 bg-indigo-50 rounded-md shadow-sm"
            >
              Coming Soon
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import router from "@/router";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

const GET_GOOGLE_OAUTH_URL = gql`
  query {
    googleoauth {
      authorizationUrl
      errors
    }
  }
`;

export default defineComponent({
  name: "SelectCalendarSection",
  components: {},
  setup() {
    const showModal = ref(true);
    const googleOAuthUrl = ref("");
    const errorMessage = ref("");
    const route = useRoute();

    onMounted(() => {
      checkGoogleCalendarSyncFailure();
    });

    const checkGoogleCalendarSyncFailure = () => {
      const googleCalendarSync = route.query.google_calendar_sync as
        | string
        | undefined;
      if (googleCalendarSync === "false") {
        const message = route.query.details as string | undefined;
        if (message) {
          errorMessage.value = `Authorization failed due to: ${message}`;
        }
        clearQueryParams();
      }
    };

    const clearQueryParams = () => {
      const { fullPath } = route;
      const newUrl = fullPath.split("?")[0];
      router.replace({ path: newUrl });
    };

    const handleModalClose = () => {
      showModal.value = false;
    };

    const connectGoogleCalendar = () => {
      fetchGoogleAuthUrl();
    };

    watch(
      () => googleOAuthUrl.value,
      (newValue) => {
        errorMessage.value = "";
        window.location.href = newValue;
      }
    );

    const fetchGoogleAuthUrl = () => {
      const { result: queryResult, error: queryError } =
        useQuery(GET_GOOGLE_OAUTH_URL);

      watch(
        () => queryResult.value,
        (newValue) => {
          console.log("Google auth value ", newValue.googleoauth.errors);
          if (newValue?.googleoauth?.errors.length > 0) {
            errorMessage.value =
              "Failed to fetch Google authorization URL. Please refresh the page and try again.";
          } else {
            googleOAuthUrl.value = newValue?.googleoauth?.authorizationUrl;
          }
        }
      );

      watch(
        () => queryError.value,
        (newError) => {
          if (newError) {
            errorMessage.value =
              "Failed to fetch Google authorization URL. Please refresh the page and try again.";
            console.error("Query error:", newError);
          }
        }
      );
    };

    return {
      showModal,
      handleModalClose,
      connectGoogleCalendar,
      errorMessage,
    };
  },
});
</script>

<style scoped>
/* Add any scoped styles if needed */
</style>
