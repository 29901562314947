<!-- TokenView.vue -->
<template>
  <div class="token-view">
    <h1>Welcome to Slashexpert</h1>
    <h1>Let's start onboarding {{ email }}</h1>
    <br />
    <h1>Your Token</h1>
    <input type="text" :value="token" readonly class="token-input" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useAuthStore } from "@/stores/auth";

export default defineComponent({
  name: "TokenView",
  setup() {
    const authStore = useAuthStore();

    const token = computed(() => authStore.token);
    const email = computed(() => authStore.email);

    return {
      token,
      email,
    };
  },
});
</script>

<style scoped>
.token-view {
  padding: 20px;
  text-align: center;
}

.token-input {
  margin-top: 10px;
  padding: 10px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
