<template>
  <label :for="forAttr" :class="labelClasses">
    {{ text }}
  </label>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "BaseLabel",
  props: {
    forAttr: {
      type: String as PropType<string>,
      required: true,
    },
    text: {
      type: String as PropType<string>,
      required: true,
    },
    labelClasses: {
      type: String as PropType<string>,
      default: "",
    },
  },
});
</script>

<style scoped>
/* Add any scoped styles here if needed */
</style>
