<template>
  <div>
    <input
      :id="id"
      :value="internalValue"
      :type="type"
      :class="inputClasses"
      :placeholder="placeholder"
      :required="required"
      @input="handleInput"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from "vue";

export default defineComponent({
  name: "BaseInput",
  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: "",
    },
    type: {
      type: String as PropType<"text" | "email" | "password" | "number">,
      default: "text",
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const internalValue = ref(props.modelValue);

    watch(
      () => props.modelValue,
      (newVal) => {
        internalValue.value = newVal;
      }
    );

    const handleInput = (event: Event) => {
      const inputElement = event.target as HTMLInputElement;
      internalValue.value = inputElement.value;
      emit("update:modelValue", inputElement.value);
    };

    return {
      internalValue,
      handleInput,
      inputClasses: `${props.className}`,
    };
  },
});
</script>
