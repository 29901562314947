import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./assets/stylesheets/tailwind.css";
import { createPinia } from "pinia";
import posthogPlugin from "../plugins/posthog";
import piniaPersistedState from "pinia-plugin-persistedstate";
import "./assets/stylesheets/global.css";
import { setupApolloClient } from "./apollo";

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPersistedState);

app.use(pinia);
app.use(router);
app.use(posthogPlugin);
setupApolloClient();
app.mount("#app");
