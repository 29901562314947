<template>
  <section
    class="flex flex-col justify-start border rounded-none px-6 py-3 max-h-[54vh] overflow-y-auto"
  >
    <label class="text-left mb-4"
      >I'm available between these time slots:</label
    >
    <article
      v-for="(day, index) in weekDays"
      :key="day"
      class="flex flex-wrap gap-4 w-full max-md:max-w-full"
      :class="{ 'mt-4': index !== 0 }"
    >
      <!-- Toggle Button -->
      <div class="flex-shrink-0 w-auto">
        <div class="flex gap-10 items-center self-stretch my-auto">
          <div class="flex gap-4 items-center self-stretch my-auto">
            <button
              class="flex items-center w-12 h-6 rounded-xl transition-colors duration-300"
              :class="{
                'bg-violet-600': toggles[day],
                'bg-gray-200': !toggles[day],
              }"
              @click="toggle(day)"
              aria-label="Toggle button"
            >
              <span
                class="flex self-stretch my-auto w-5 h-5 bg-white rounded-full transition-transform duration-300 transform"
                :class="{
                  'translate-x-6': toggles[day],
                  'translate-x-0.5': !toggles[day],
                }"
              ></span>
            </button>
            <h2
              class="self-stretch my-auto w-20 text-sm font-medium leading-none text-zinc-800"
            >
              {{ day }}
            </h2>
          </div>
        </div>
      </div>

      <!-- Start Time Picker -->
      <div v-show="toggles[day]" class="flex flex-col">
        <div
          class="flex flex-col"
          v-for="(availability, index) in schedule[day]"
          :key="index"
          :class="{ 'mt-2': index > 0 }"
        >
          <!-- Flex container for time pickers and buttons -->
          <div class="flex flex-row items-center">
            <div class="flex-shrink-0 w-auto">
              <CustomTimePicker
                v-model:defaultTime="availability.startTime"
                @update:defaultTime="
                  updateTime(day, index, 'startTime', $event)
                "
              />
            </div>

            <!-- Dash separator -->
            <div
              class="flex-shrink-0 w-3 min-h-1/2 flex items-center justify-center"
            >
              <span class="text-base text-gray-900">-</span>
            </div>

            <!-- End Time Picker -->
            <div class="flex-shrink-0 w-auto">
              <CustomTimePicker
                v-model:defaultTime="availability.endTime"
                @update:defaultTime="updateTime(day, index, 'endTime', $event)"
              />
            </div>

            <!-- Edit and Delete Buttons -->
            <div class="flex-shrink-0 w-auto flex gap-4 ml-4">
              <button
                v-if="index == 0"
                class="object-contain shrink-0 self-stretch my-auto aspect-square"
                aria-label="Edit schedule"
                @click="addAvailability(day)"
              >
                <!-- SVG for edit button -->
                <div v-html="CIRCLE_PLUS" class="text-primary-purple"></div>
              </button>
              <button
                v-else
                class="object-contain shrink-0 self-stretch my-auto aspect-square"
                aria-label="Delete schedule"
                @click="deleteAvailability(day, index)"
              >
                <!-- SVG for delete button -->
                <div v-html="CIRCLE_MINUS" class="text-red-600"></div>
              </button>
              <button
                class="object-contain shrink-0 self-stretch my-auto aspect-square"
                aria-label="Add note."
                @click="openNoteModal(day, index)"
              >
                <!-- SVG for add note button -->
                <div v-html="NOTE_ICON" class="text-primary-purple"></div>
              </button>
            </div>
          </div>

          <!-- Label Div below the CustomTimePickers and Buttons -->
          <div class="flex mt-2">
            <label :id="`${day}-${index}`" class="text-red-600"></label>
          </div>
        </div>
      </div>
    </article>

    <!-- Availability Note Modal -->
    <AvailabilityNoteModal
      v-if="isNoteModalVisible"
      :isVisible="isNoteModalVisible"
      :day="selectedDay"
      :startTime="selectedStartTime"
      :endTime="selectedEndTime"
      :initialNote="selectedNote"
      @close="isNoteModalVisible = false"
      @save="saveNote"
    />
  </section>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from "vue";
import CustomTimePicker from "@/components/onboarding/CustomTimePicker.vue";
import AvailabilityNoteModal from "@/components/onboarding/AvailabilityNoteModal.vue";
import {
  WeekSchedule,
  DaySchedule,
  DailyAvailabilities,
} from "../../types/onboarding/types";
import {
  CIRCLE_MINUS,
  NOTE_ICON,
} from "@/assets/svg/expert-overview/svgConstants";
import { CIRCLE_PLUS } from "@/assets/svg/shared/svgConstants";

type Day =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";

export default defineComponent({
  name: "SetAvailability",
  components: {
    CustomTimePicker,
    AvailabilityNoteModal,
  },
  props: {
    initialSchedule: {
      type: Object as PropType<WeekSchedule>,
      required: true,
    },
  },
  emits: ["updateSchedule", "recheckAvailability"],
  setup(props, { emit }) {
    const weekDays: Day[] = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const dailyAvailabilities = ref<DailyAvailabilities>({
      Monday: 1,
      Tuesday: 1,
      Wednesday: 1,
      Thursday: 1,
      Friday: 1,
      Saturday: 1,
      Sunday: 1,
    });

    const schedule = ref<WeekSchedule>({ ...props.initialSchedule });

    const toggles = ref<Record<Day, boolean>>(
      weekDays.reduce((acc, day) => {
        acc[day] = true;
        return acc;
      }, {} as Record<Day, boolean>)
    );

    const isNoteModalVisible = ref(false);
    const selectedDay = ref<Day>();
    const selectedStartTime = ref("");
    const selectedEndTime = ref("");
    const selectedNote = ref("");
    const selectedIndex = ref<number | null>(null);

    const toggle = (day: Day) => {
      if (toggles.value[day]) {
        schedule.value[day] = [];
      } else {
        schedule.value[day] = [
          { startTime: "09:00 AM", endTime: "05:00 PM", note: "" },
        ];
      }
      toggles.value[day] = !toggles.value[day];
      emit("updateSchedule", schedule.value);
    };

    const updateTime = (
      day: Day,
      index: number,
      timeType: keyof DaySchedule,
      time: string
    ) => {
      schedule.value[day][index][timeType] = time;
      emit("updateSchedule", schedule.value);
    };

    const addAvailability = (day: Day) => {
      schedule.value[day].push({
        startTime: "09:00 AM",
        endTime: "05:00 PM",
        note: "",
      });
      dailyAvailabilities.value[day] = dailyAvailabilities.value[day] + 1;
      emit("updateSchedule", schedule.value);
    };

    const deleteAvailability = (day: Day, index: number) => {
      schedule.value[day].splice(index, 1);
      dailyAvailabilities.value[day] = Math.max(
        dailyAvailabilities.value[day] - 1,
        0
      );
      emit("updateSchedule", schedule.value);
      emit("recheckAvailability");
    };

    const openNoteModal = (day: Day, index: number) => {
      selectedDay.value = day;
      selectedStartTime.value = schedule.value[day][index].startTime;
      selectedEndTime.value = schedule.value[day][index].endTime;
      selectedNote.value = schedule.value[day][index].note;
      selectedIndex.value = index;
      isNoteModalVisible.value = true;
    };

    const saveNote = (note: string) => {
      const day = selectedDay.value as Day;
      const index = selectedIndex.value;
      if (index !== null) {
        schedule.value[day][index].note = note;
        emit("updateSchedule", schedule.value);
      }
      isNoteModalVisible.value = false;
    };

    watch(
      schedule,
      (newValue) => {
        emit("updateSchedule", newValue);
      },
      { deep: true }
    );

    return {
      weekDays,
      schedule,
      toggles,
      toggle,
      updateTime,
      addAvailability,
      deleteAvailability,
      dailyAvailabilities,
      openNoteModal,
      saveNote,
      isNoteModalVisible,
      selectedDay,
      selectedStartTime,
      selectedEndTime,
      selectedNote,
      CIRCLE_PLUS,
      CIRCLE_MINUS,
      NOTE_ICON,
    };
  },
});
</script>
