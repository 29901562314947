import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex gap-1.5 items-center" }
const _hoisted_2 = { class: "text-sm tracking-wide text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative flex justify-center items-center p-2.5 my-auto w-6 h-6 rounded-full", {
        'bg-gray-100': !_ctx.isValid,
        'bg-green-100': _ctx.isValid,
      }])
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(["fas fa-check", {
          'text-gray-300': !_ctx.isValid,
          'text-green-300': _ctx.isValid,
        }]),
        style: {"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)"}
      }, null, 2)
    ], 2),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.text), 1)
  ]))
}