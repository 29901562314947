import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "value", "type", "placeholder", "required"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("input", {
      id: _ctx.id,
      value: _ctx.internalValue,
      type: _ctx.type,
      class: _normalizeClass(_ctx.inputClasses),
      placeholder: _ctx.placeholder,
      required: _ctx.required,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
    }, null, 42, _hoisted_1)
  ]))
}