<template>
  <div class="relative" ref="container">
    <button
      @click="toggleDropdown"
      class="w-full px-4 py-2 border border-gray-300 mr-2 rounded-md bg-white text-left flex justify-between items-center text-sm"
    >
      <span>{{ selectedTime }}</span>
      <i
        :class="`fas fa-chevron-${
          isDropdownOpen ? 'up' : 'down'
        } text-gray-600`"
      ></i>
    </button>
    <div
      v-if="isDropdownOpen"
      :class="dropdownClass"
      class="absolute w-full border border-gray-300 bg-white rounded-md shadow-lg z-10 max-h-60 overflow-y-auto text-sm"
    >
      <ul class="divide-y divide-gray-200">
        <li
          v-for="time in sortedTimeOptions"
          :key="time"
          @click="selectTime(time)"
          class="px-4 py-2 cursor-pointer hover:bg-gray-100"
        >
          {{ time }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  ref,
  computed,
  watch,
  onMounted,
  onBeforeUnmount,
} from "vue";

export default defineComponent({
  name: "CustomTimePicker",
  props: {
    defaultTime: {
      type: String,
      default: "01:30 PM",
    },
  },
  emits: ["update:defaultTime"],
  setup(props, { emit }) {
    const isDropdownOpen = ref(false);
    const selectedTime = ref(props.defaultTime);
    const container = ref<HTMLElement | null>(null);
    const dropdownClass = ref("top-full");

    const generateTimeOptions = () => {
      const times: string[] = [];
      const intervals = 30;

      for (let hour = 0; hour < 24; hour++) {
        const hour12 = (hour % 12 || 12).toString().padStart(2, "0");
        const period = hour < 12 ? "AM" : "PM";

        for (let minute = 0; minute < 60; minute += intervals) {
          const minuteFormatted = minute.toString().padStart(2, "0");
          const time = `${hour12}:${minuteFormatted} ${period}`;
          times.push(time);
        }
      }

      return times;
    };

    const timeOptions = computed(() => generateTimeOptions());

    const sortedTimeOptions = computed(() => {
      const amTimes = timeOptions.value.filter((time) => time.includes("AM"));
      const pmTimes = timeOptions.value.filter((time) => time.includes("PM"));
      return [...amTimes, ...pmTimes];
    });

    const toggleDropdown = () => {
      isDropdownOpen.value = !isDropdownOpen.value;
      if (isDropdownOpen.value) {
        adjustDropdownPosition();
      }
    };

    const selectTime = (time: string) => {
      selectedTime.value = time;
      emit("update:defaultTime", time);
      isDropdownOpen.value = false;
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (container.value && !container.value.contains(event.target as Node)) {
        isDropdownOpen.value = false;
      }
    };

    const adjustDropdownPosition = () => {
      if (container.value) {
        const { bottom, height } = container.value.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        if (bottom + height + 240 > viewportHeight) {
          dropdownClass.value = "bottom-full";
        } else {
          dropdownClass.value = "top-full";
        }
      }
    };

    onMounted(() => {
      document.addEventListener("mousedown", handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener("mousedown", handleClickOutside);
    });

    watch(
      () => props.defaultTime,
      (newValue) => {
        selectedTime.value = newValue;
      }
    );

    return {
      isDropdownOpen,
      sortedTimeOptions,
      selectedTime,
      toggleDropdown,
      selectTime,
      container,
      dropdownClass,
    };
  },
});
</script>
