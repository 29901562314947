import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import SignInView from "@/views/SignInView.vue";
import SignUpView from "@/views/SignUpView.vue";
import OnboardingView from "@/views/OnboardingView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import ResetPasswordView from "@/views/ResetPasswordView.vue";
import OnboardingProfileInformation from "@/views/onboarding/ProfileInformationView.vue";
import OnboardingSoftwareExpertise from "@/views/onboarding/SoftwareExpertiseView.vue";
import OnboardingCalendar from "@/views/onboarding/CalendarView.vue";
import ExpertDashboard from "@/views/ExpertDashboardView.vue";
import ExpertExpertise from "@/views/ExpertExpertiseView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "SignIn",
    component: SignInView,
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUpView,
  },
  {
    path: "/onboarding",
    name: "Onboarding",
    component: OnboardingView,
  },
  {
    path: "/forgotpassword",
    name: "ForgotPassword",
    component: ForgotPasswordView,
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    component: ResetPasswordView,
  },
  {
    path: "/onboarding/profile_information",
    name: "OnboardingProfileInformation",
    component: OnboardingProfileInformation,
  },
  {
    path: "/onboarding/software_expertise",
    name: "OnboardingSoftwareExpertise",
    component: OnboardingSoftwareExpertise,
  },
  {
    path: "/onboarding/calendar",
    name: "OnboardingCalendar",
    component: OnboardingCalendar,
  },
  {
    path: "/expert/dashboard",
    name: "ExpertDashboard",
    component: ExpertDashboard,
  },
  {
    path: "/expert/expertise",
    name: "ExpertExpertise",
    component: ExpertExpertise,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
