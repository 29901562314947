<template>
  <div class="flex overflow-hidden flex-col pb-60 bg-white max-md:pb-24">
    <header class="flex flex-col w-full max-md:max-w-full">
      <ExpertiseNavbar :activeTab="0" />
    </header>
    <main
      class="flex flex-col px-10 mt-10 w-full max-md:px-5 max-md:max-w-full"
    >
      <h1 class="self-start text-3xl font-semibold leading-tight text-black">
        Welcome, John!
      </h1>
      <div class="flex justify-center mb-4">
        <div
          v-if="dashboardError"
          role="alert"
          class="flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
          style="max-height: 80px; overflow-y: auto"
        >
          <div
            class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
          >
            <img
              src="../assets//shared/failure.svg"
              alt="Error Icon"
              class="w-4 h-4"
            />
          </div>
          <p>{{ dashboardError }}</p>
        </div>
      </div>
      <div class="mt-10 max-md:max-w-full">
        <div class="flex gap-2 max-md:flex-col">
          <section class="flex flex-col w-[74%] max-md:ml-0 max-md:w-full">
            <div class="flex flex-col w-full max-md:mt-8 max-md:max-w-full">
              <div class="flex flex-col max-md:max-w-full">
                <div class="flex gap-5">
                  <InformationBlocks
                    :totalPrograms="programCountString"
                    :upcomingMeetings="bookingCountString"
                  />
                </div>
              </div>
              <section class="flex flex-col mt-10 w-full max-md:max-w-full">
                <h2
                  class="flex justify-between items-end w-full text-lg font-medium leading-none text-gray-900 max-md:max-w-full"
                >
                  <div class="flex flex-col">
                    <div class="w-full">Expert Programs</div>
                  </div>
                </h2>
                <div
                  class="flex flex-wrap gap-5 items-start mt-4 w-full max-md:max-w-full"
                >
                  <article
                    v-for="(program, index) in programs"
                    :key="index"
                    class="flex shrink shrink gap-2.5 items-start px-4 py-4 bg-white rounded border border-gray-300 border-solid"
                  >
                    <div class="flex flex-col">
                      <div class="flex flex-col w-full">
                        <div class="flex flex-col w-full">
                          <div class="flex gap-3 items-center self-start">
                            <div
                              class="relative w-9 h-9 overflow-hidden rounded-full flex items-center justify-center"
                            >
                              <img
                                loading="lazy"
                                :src="cardLogo(program)"
                                alt="User profile picture"
                                class="w-full h-full object-cover"
                              />
                              <div class="absolute inset-0"></div>
                            </div>
                            <h3
                              class="self-stretch my-auto w-52 text-lg font-medium leading-none text-left text-gray-900"
                            >
                              {{ program.name }}
                            </h3>
                          </div>
                          <div v-if="program.companyId">
                            <p
                              class="mt-4 w-full text-sm leading-5 text-left text-gray-500"
                            >
                              {{ truncateText(program.description) }}
                            </p>
                          </div>
                          <div v-else class="max-w-[200px] mb-0.5 py-1">
                            <p
                              class="mt-4 w-full text-sm leading-5 text-left text-gray-500 break-words"
                            >
                              No expert program currently available. Will update
                              you as soon as there is one.
                            </p>
                          </div>
                        </div>
                        <div
                          v-if="program.companyId"
                          class="flex overflow-hidden justify-center items-center self-start text-sm font-medium tracking-normal leading-5 text-center whitespace-nowrap rounded-lg border border-violet-300 border-solid mt-4 text-zinc-900"
                        >
                          <div class="gap-2 self-stretch px-4 py-1.5 my-auto">
                            ${{ program.payout }}/meeting
                          </div>
                        </div>
                      </div>
                      <button
                        @click="handleViewDetails(program)"
                        class="overflow-hidden gap-3 self-stretch py-2.5 pr-4 pl-4 mt-8 w-full text-base font-medium text-violet-600 bg-violet-100 rounded-md"
                      >
                        View detail
                      </button>
                    </div>
                  </article>
                </div>
              </section>
            </div>
          </section>
          <aside class="flex flex-col ml-5 w-[26%] max-md:ml-0 max-md:w-full">
            <div
              class="flex flex-col px-4 py-5 mx-auto w-full bg-white rounded border border-gray-300 border-solid max-md:mt-8"
            >
              <div class="flex items-center gap-1.5 max-w-full mb-2">
                <!-- Icon -->
                <div class="text-base leading-none text-violet-600">
                  <i class="far fa-calendar-check"></i>
                </div>
                <!-- Text -->
                <h2 class="text-sm font-medium leading-none text-zinc-800">
                  <div class="max-w-full">Your Availability</div>
                </h2>
              </div>
              <div class="h-[50vh] overflow-y-auto">
                <ul
                  class="flex flex-col mt-3.5 w-full text-sm leading-6 text-black"
                >
                  <li
                    v-for="(slots, day) in availabilitySchedule"
                    :key="day"
                    class="flex flex-col gap-2.5 items-start px-2.5 py-2 mt-3 w-full bg-gray-50 rounded"
                  >
                    <h3 class="font-medium">{{ day }}</h3>
                    <div
                      v-for="(slot, index) in slots"
                      :key="index"
                      class="flex gap-2.5 items-center w-full bg-gray-50 rounded"
                    >
                      <span class="self-stretch my-auto">
                        {{ slot.startTime }} to {{ slot.endTime }}
                      </span>
                      <div
                        class="flex shrink-0 self-stretch my-auto w-4 h-4"
                      ></div>
                    </div>
                  </li>
                </ul>
              </div>
              <BaseButton
                type="button"
                class="overflow-hidden gap-3 self-stretch py-2.5 pr-4 pl-4 mt-20 text-base font-medium text-violet-600 bg-violet-100 rounded-md max-md:mt-10"
                @click="openModal"
              >
                Update schedule
              </BaseButton>
            </div>
          </aside>
        </div>
      </div>
    </main>
  </div>
  <UpdateAvailabilityModal
    :showModal="showModal"
    @closeModal="closeModal"
    @schedule="setSchedule"
  />
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import router from "@/router";
import { useQuery } from "@vue/apollo-composable";
import { gql } from "@apollo/client/core";
import InformationBlocks from "@/components/dashboard/InformationBlocks.vue";
import UpdateAvailabilityModal from "@/components/dashboard/UpdateAvailabilityModal.vue";
import { Program, WeekSchedule } from "@/types/dashboard/types";
import { useExpertProgramsStore } from "@/stores/expertPrograms";
import ExpertiseNavbar from "@/components/expertise/ExpertiseNavbar.vue";
import {
  COMPANY_LOGO,
  CLOSE_ICON,
  TRIANGLE_ERROR_ICON,
} from "@/assets/svg/shared/svgConstants";
import {
  DASHBOARD_ICON,
  EXPERTISE_ICON,
  EARNINGS_ICON,
  PROGRAMS_ICON,
  PHONE_ICON,
} from "@/assets/svg/expert-overview/svgConstants";

const GET_PROGRAMS = gql`
  query GetPrograms($userBased: Boolean!) {
    getProgram(userBased: $userBased) {
      id
      name
      website
      logo
      experts
      description
      payout
      companyId
    }
  }
`;

const GET_BOOKINGS = gql`
  query GetBookings($totalBookings: Boolean) {
    listBooking(totalBookings: $totalBookings) {
      bookings {
        id
        day
        startTime
        endTime
      }
      totalCount
    }
  }
`;

export default defineComponent({
  name: "ExpertDashboard",
  components: {
    BaseButton,
    InformationBlocks,
    UpdateAvailabilityModal,
    ExpertiseNavbar,
  },
  setup() {
    const authProfileInformation = ref("");
    const dashboardError = ref("");
    const totalBookings = ref(0);
    const isDisabled = ref(false);
    const programs = ref<Program[]>([]);
    const errorMessage = ref("");
    const programsStore = useExpertProgramsStore();

    const availabilitySchedule = ref<WeekSchedule>({
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
    });

    const {
      result: programsResult,
      error: programsError,
      refetch: refetchPrograms,
    } = useQuery(GET_PROGRAMS, {
      userBased: true,
    });

    const { result: bookingsResult, error: bookingsError } = useQuery(
      GET_BOOKINGS,
      {
        totalBookings: true,
      },
      {
        errorPolicy: "all",
      }
    );

    refetchPrograms();

    const programCountString = computed(() => {
      const count = programs.value.length;
      return count > 9 ? String(count) : `0${count}`;
    });

    const bookingCountString = computed(() => {
      const count = totalBookings.value;
      return count > 9 ? String(count) : `0${count}`;
    });

    watch(
      () => programsResult.value,
      (newValue) => {
        if (newValue) {
          programs.value = newValue.getProgram;
          programsStore.setPrograms(programs.value);
        }
      },
      { immediate: true }
    );

    watch(
      () => programsError.value,
      (queryError) => {
        if (queryError) {
          dashboardError.value =
            "Failed to fetch necessary data, please refresh the page to try again.";
          console.error("Profile update failed:", queryError.message);
          console.error("GraphQL Errors:", queryError.graphQLErrors);
          console.error("Network Error:", queryError.networkError);
        }
      },
      { immediate: true }
    );

    watch(
      () => bookingsResult.value,
      (newValue) => {
        if (newValue?.listBooking?.totalCount) {
          totalBookings.value = newValue.listBooking.totalCount;
        }
      },
      { immediate: true }
    );

    watch(
      () => bookingsError.value,
      (queryError) => {
        if (queryError) {
          dashboardError.value =
            "Failed to fetch necessary data, please refresh the page to try again.";
          console.error("Profile update failed:", queryError.message);
          console.error("GraphQL Errors:", queryError.graphQLErrors);
          console.error("Network Error:", queryError.networkError);
        }
      },
      { immediate: true }
    );

    const navigateToDashboard = () => {
      router.push({ name: "ExpertDashboard" });
    };

    const navigateToExpertise = () => {
      router.push({ name: "ExpertExpertise" });
    };

    const navigateToEarning = () => {
      // router.push({ name: "ExpertEarnings" });
      console.log("Comnig Soon");
    };

    const showModal = ref(false);

    const openModal = () => {
      showModal.value = true;
    };

    const closeModal = () => {
      errorMessage.value = "";
      showModal.value = false;
    };

    const handleViewDetails = (program: Program) => {
      programsStore.setSelectedProgram(program);
      router.push({ name: "ExpertExpertise" });
    };

    const cardLogo = (program: Program) => {
      return program.logo ? program.logo : logoFromWebsite(program.website);
    };

    const logoFromWebsite = (websiteUrl: string): string => {
      const urlTemplate =
        "https://img.logo.dev/{domain}?token=pk_R1LW6BZBRPKs6t3nZphCOw";
      const placeholder = "{domain}";

      try {
        const url = new URL(websiteUrl);
        const domain = url.hostname.split(".").slice(-2).join(".");

        return urlTemplate.replace(placeholder, domain);
      } catch (error) {
        return urlTemplate.replace(placeholder, "www.logo.com");
      }
    };

    const setSchedule = (newSchedule: WeekSchedule) => {
      availabilitySchedule.value = newSchedule;
    };

    const truncateText = (text: string, maxLength = 35) => {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      } else if (text.length == 0) {
        return "No description provided.";
      }
      return text;
    };

    return {
      authProfileInformation,
      navigateToDashboard,
      navigateToExpertise,
      navigateToEarning,
      availabilitySchedule,
      closeModal,
      openModal,
      showModal,
      setSchedule,
      programs,
      programCountString,
      bookingCountString,
      isDisabled,
      truncateText,
      cardLogo,
      errorMessage,
      dashboardError,
      handleViewDetails,
      COMPANY_LOGO,
      DASHBOARD_ICON,
      EXPERTISE_ICON,
      PROGRAMS_ICON,
      EARNINGS_ICON,
      PHONE_ICON,
      CLOSE_ICON,
      TRIANGLE_ERROR_ICON,
    };
  },
});
</script>
