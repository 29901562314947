<template>
  <div
    v-if="errorMessage"
    role="alert"
    class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-h-20 overflow-auto"
  >
    <!-- Icon Container -->
    <div
      class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
    >
      <img
        src="../../assets/shared/failure.svg"
        alt="Error Icon"
        class="w-4 h-4"
      />
    </div>
    <p>{{ errorMessage }}</p>
  </div>
  <div class="flex flex-col w-full max-md:mt-8 max-md:max-w-full">
    <div class="flex flex-col max-md:max-w-full">
      <div class="flex gap-5">
        <div class="flex flex-wrap gap-5 flex-1">
          <article class="flex flex-col flex-1 max-md:ml-0 max-md:w-full">
            <div
              class="flex flex-col mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid max-md:mt-5 h-full"
            >
              <div class="flex gap-5 items-center p-6 w-full max-md:px-5">
                <div
                  class="flex justify-center items-center self-stretch px-3 my-auto w-12 h-12 bg-violet-600 rounded-md"
                >
                  <div v-html="CALENDAR_ICON" class="text-white"></div>
                </div>
                <div
                  class="flex flex-col flex-1 shrink self-stretch my-auto whitespace-nowrap basis-6"
                >
                  <div
                    class="text-sm font-medium leading-none text-gray-500 text-left"
                  >
                    Meetings This Week
                  </div>
                  <div
                    class="gap-2 self-start text-2xl mt-2 font-semibold leading-none text-zinc-800"
                  >
                    {{ programDetail?.upcomingMeetings }}
                  </div>
                </div>
              </div>
              <BaseButton
                type="button"
                class="flex flex-col justify-center items-start p-4 w-full text-sm font-medium leading-none text-indigo-600 bg-gray-50 text-left"
              >
                <span class="self-stretch">See Details</span>
              </BaseButton>
            </div>
          </article>
          <article class="flex flex-col flex-1 max-md:ml-0 max-md:w-full">
            <div
              class="flex flex-col mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid max-md:mt-5 h-full"
            >
              <div class="flex gap-5 items-center p-6 w-full max-md:px-5">
                <div
                  class="flex justify-center items-center self-stretch px-3 my-auto w-12 h-12 bg-violet-600 rounded-md"
                >
                  <div v-html="PEOPLE_ICON" class="text-white"></div>
                </div>
                <div
                  class="flex flex-col flex-1 shrink self-stretch my-auto basis-6"
                >
                  <div
                    class="text-sm font-medium leading-none text-gray-500 text-left"
                  >
                    Total {{ program.name }} Experts
                  </div>
                  <div
                    class="gap-2 self-start text-2xl mt-2 font-semibold leading-none whitespace-nowrap text-zinc-800"
                  >
                    {{ programDetail?.totalExperts }}
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article class="flex flex-col flex-1 max-md:ml-0 max-md:w-full">
            <div
              class="flex flex-col mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid max-md:mt-5 h-full"
            >
              <div class="flex gap-5 items-center p-6 w-full max-md:px-5">
                <div
                  class="flex justify-center items-center self-stretch px-3 my-auto w-12 h-12 bg-violet-600 rounded-md"
                >
                  <div v-html="EARNINGS_ICON" class="text-white"></div>
                </div>
                <div
                  class="flex flex-col flex-1 shrink self-stretch my-auto basis-6"
                >
                  <div
                    class="text-sm font-medium leading-none text-gray-500 text-left"
                  >
                    Total Earnings
                  </div>
                  <div
                    class="gap-2 self-start text-2xl mt-2 font-semibold leading-none whitespace-nowrap text-zinc-800"
                  >
                    ${{ programDetail?.totalEarnings }}
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </div>
    <section class="flex flex-col mt-10 w-full max-md:max-w-full">
      <h2
        class="flex justify-between items-end w-full text-lg font-medium leading-none text-gray-900 max-md:max-w-full"
      >
        <div class="flex flex-col">
          <div class="w-full">Resources</div>
        </div>
      </h2>
      <div
        class="flex flex-wrap gap-5 items-start mt-4 w-full max-md:max-w-full"
      >
        <article
          v-for="(resource, index) in programDetail?.resources"
          :key="index"
          class="flex shrink shrink gap-2.5 items-start bg-white"
        >
          <article class="flex flex-col flex-1 max-md:ml-0 max-md:w-full">
            <a
              :href="resource"
              target="_blank"
              rel="noopener noreferrer"
              class="block w-full h-full"
            >
              <div
                class="flex flex-col mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid max-md:mt-5 h-full"
              >
                <div class="flex gap-5 items-center w-full max-md:px-5">
                  <div
                    class="flex justify-center items-center self-stretch px-4 w-15 h-15 bg-red-600 rounded-md"
                  >
                    <span class="text-white">PDF</span>
                  </div>
                  <div
                    class="flex flex-col flex-1 shrink self-stretch my-auto basis-6"
                  >
                    <div
                      class="text-sm font-medium leading-5 text-gray-900 text-left pr-6 mt-2"
                    >
                      {{ truncateText("Company Resource") }}
                    </div>
                    <div
                      class="text-gray-500 text-sm font-inter font-normal leading-5 mb-2 text-left"
                    >
                      16 MB
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </article>
        </article>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from "vue";
import { Program } from "@/types/dashboard/types";
import BaseButton from "../shared/BaseButton.vue";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";
import {
  CALENDAR_ICON,
  PEOPLE_ICON,
  EARNINGS_ICON,
} from "@/assets/svg/expert-overview/svgConstants";

interface ProgramDetail {
  id: string;
  totalExperts: number;
  upcomingMeetings: number;
  totalEarnings: number;
  resources: [string];
  contactEmail: string;
  errors: [string];
}

const GET_PROGRAM_DETAIL = gql`
  query GetProgramDetail($programId: ID!) {
    getProgramDetail(programId: $programId) {
      id
      totalExperts
      upcomingMeetings
      totalEarnings
      resources
      contactEmail
      errors
    }
  }
`;

export default defineComponent({
  name: "AcceptedProgramDetailsSection",
  components: {
    BaseButton,
  },
  props: {
    program: {
      type: Object as PropType<Program>,
      required: true,
    },
  },
  setup(props) {
    const errorMessage = ref("");
    const programDetail = ref<ProgramDetail | null>(null);
    const { result: programDetailResult, error: programDetailError } = useQuery(
      GET_PROGRAM_DETAIL,
      {
        programId: props.program.id,
      }
    );

    watch(
      () => programDetailResult.value,
      (result) => {
        if (result) {
          if (result.getProgramDetail?.getProgramDetail?.errors.length > 0) {
            errorMessage.value =
              result.getProgramDetail.getProgramDetail.errors.join(". ");
          } else {
            const resultFields = result.getProgramDetail;
            programDetail.value = {
              id: resultFields.id,
              totalExperts: resultFields.totalExperts,
              upcomingMeetings: resultFields.upcomingMeetings,
              totalEarnings: resultFields.totalEarnings,
              resources: resultFields.resources,
              contactEmail: resultFields.contactEmail,
            } as ProgramDetail;
          }
        }
      },
      { immediate: true }
    );

    watch(
      () => programDetailError.value,
      (error) => {
        if (error) {
          console.log("Program Detail result ", error);
        }
      },
      { immediate: true }
    );

    const truncateText = (text: string, maxLength = 15) => {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      } else if (text.length == 0) {
        return "No description provided.";
      }
      return text;
    };

    return {
      errorMessage,
      programDetail,
      truncateText,
      CALENDAR_ICON,
      PEOPLE_ICON,
      EARNINGS_ICON,
    };
  },
});
</script>
