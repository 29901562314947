<template>
  <div
    v-if="showModal"
    class="fixed inset-0 flex items-center justify-center z-50"
  >
    <div class="fixed inset-0 bg-gray-900 opacity-50"></div>

    <div
      class="relative bg-white p-6 rounded-lg shadow-lg z-10 w-4/5 max-w-4xl h-[80vh] flex flex-col"
    >
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-lg font-semibold">Update Calendar</h2>
        <button
          @click="closeModal"
          class="text-gray-500 hover:text-gray-700"
          :disabled="isDisabled"
        >
          <div v-html="CLOSE_ICON"></div>
        </button>
      </div>

      <div class="flex justify-center mb-4">
        <div
          v-if="errorMessage"
          role="alert"
          class="flex gap-2 w-1/2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
          style="max-height: 80px; overflow-y: auto"
        >
          <div
            class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
          >
            <img
              src="../../assets/shared/failure.svg"
              alt="Error Icon"
              class="w-4 h-4"
            />
          </div>
          <p>{{ errorMessage }}</p>
        </div>
      </div>

      <div class="flex-1 mb-5 overflow-auto">
        <section class="flex flex-col w-1/2 my-2">
          <div class="flex flex-col sm:flex-row items-center gap-2">
            <label for="timezone" class="text-sm font-medium whitespace-nowrap"
              >Select Timezone:</label
            >
            <BaseSelect
              id="timezone-select"
              label=""
              :options="timezoneOptions"
              v-model="selectedTimezone"
              placeholder=""
            />
          </div>
        </section>
        <ChangeAvailability
          :initialSchedule="availabilitySchedule"
          @updateSchedule="handleScheduleUpdate"
          @updateMarkedForDeletion="handleMarkedForDeletion"
        />
      </div>

      <div class="flex justify-center p-4">
        <BaseButton
          class="bg-violet-600 text-white p-3 border min-w-[30%] rounded-md text-base font-medium max-md:mt-10"
          type="button"
          :disabled="isDisabled"
          @click="updateAvailability"
        >
          {{ calendarUpdateButtonText }}
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {
  HOME_ICON,
  MUSICAL_NOTES_ICON,
  TRIANGLE_ERROR_ICON,
  CLOSE_ICON,
} from "@/assets/svg/shared/svgConstants";
import { computed, defineComponent, ref, watch } from "vue";
import BaseSelect from "@/components/shared/BaseSelect.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { Option } from "@/types/onboarding/types";
import {
  ModifyAvailability,
  DeletePayload,
  Availability,
  WeekSchedule,
  GetAvailabilitiesResponse,
  AvailabilityNode,
} from "@/types/dashboard/types";
import moment from "moment";
import { ApolloError, gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import ChangeAvailability from "@/components/dashboard/ChangeAvailability.vue";

const CREATE_AVAILABILITY = gql`
  mutation CreateAvailibility($availibilityParams: [AvailibilityInput!]!) {
    createAvailibility(input: { availibilityParams: $availibilityParams }) {
      message
      success
      errors
      availibilities {
        id
        day
        startTime
        endTime
        timezone
        note
      }
    }
  }
`;

const GET_AVAILABILITIES = gql`
  query GetAvailabilities($fetchAllSlots: Boolean) {
    listAvailibility(fetchAllSlots: $fetchAllSlots) {
      edges {
        node {
          id
          day
          startTime
          endTime
          timezone
          note
        }
      }
    }
  }
`;

export default defineComponent({
  name: "UpdateAvailabilityModal",
  props: {
    showModal: Boolean,
    initialSchdeule: {
      type: Object as () => WeekSchedule,
    },
  },
  components: {
    BaseSelect,
    ChangeAvailability,
    BaseButton,
  },
  emits: ["closeModal", "schedule"],
  setup(_, { emit }) {
    const isDisabled = ref(false);
    const errorMessage = ref("");
    const getTimezones = (): Option[] => {
      return moment.tz.names().map((tz) => {
        const offset = moment.tz(tz).utcOffset();
        const hours = Math.floor(offset / 60);
        const sign = hours >= 0 ? "+" : "-";
        const absHours = Math.abs(hours);
        return {
          value: tz,
          label: `${tz} (${sign}${absHours})`,
        };
      });
    };
    const timezoneOptions = ref<Option[]>(getTimezones());
    const selectedTimezone = ref<Option>(timezoneOptions.value[0]);
    const deleteAvailabilities = ref<Availability[]>([]);
    const availabilitySchedule = ref<WeekSchedule>({
      Monday: [],
      Tuesday: [],
      Wednesday: [],
      Thursday: [],
      Friday: [],
      Saturday: [],
      Sunday: [],
    });

    const {
      result: availabilitiesResult,
      error: availabilitiesError,
      refetch: refetchAvailibilities,
    } = useQuery<GetAvailabilitiesResponse>(
      GET_AVAILABILITIES,
      {
        fetchAllSlots: true,
      },
      {
        errorPolicy: "all",
      }
    );

    refetchAvailibilities();

    const {
      mutate: CreateAvailability,
      onDone,
      onError,
    } = useMutation(CREATE_AVAILABILITY);

    const closeModal = () => {
      errorMessage.value = "";
      emit("closeModal");
    };

    const handleScheduleUpdate = (newSchedule: WeekSchedule) => {
      availabilitySchedule.value = newSchedule;
      validateAvailabilityParams();
    };

    const validateAvailabilityParams = () => {
      const timeFormat = "hh:mm A";
      let valid = true;
      let payload: ModifyAvailability[] = [];
      let errorMessage = "Start time must be less than end time";
      let errorContent = `
          ${TRIANGLE_ERROR_ICON}${errorMessage}
        `;

      for (const [day, entries] of Object.entries(availabilitySchedule.value)) {
        entries.forEach((entry, index) => {
          if (entry.startTime != "00:00") {
            let start = moment(entry.startTime, timeFormat);
            let end = moment(entry.endTime, timeFormat);
            let label = document.querySelector(
              `#${day}-${index}`
            ) as HTMLLabelElement | null;
            if (start.isSameOrAfter(end)) {
              setInvalidTimeIntervalError(label, errorContent);
              valid = false;
            } else {
              setInvalidTimeIntervalError(label, "");
            }
            if (valid) {
              const payloadEntry: ModifyAvailability = {
                day: day,
                startTime: moment(entry.startTime, timeFormat).format("HH:mm"),
                endTime: moment(entry.endTime, timeFormat).format("HH:mm"),
                note: entry.note,
                timezone: selectedTimezone.value.value,
              };
              if (entry.id) {
                payloadEntry.id = entry.id;
                payloadEntry._destroy = false;
              }

              payload.push(payloadEntry);
            }
          }
        });
      }
      return { valid, payload };
    };

    const setInvalidTimeIntervalError = (
      label: HTMLLabelElement | null,
      message: string
    ): void => {
      if (label) {
        label.innerHTML = message;
      }
    };

    const handleMarkedForDeletion = (markedAvailability: Availability[]) => {
      const newArray = markedAvailability.map((entry) => {
        return {
          ...entry,
          start: entry.startTime,
          end: entry.endTime,
        };
      });

      deleteAvailabilities.value.push(
        ...newArray.filter((availability) => availability.id.length > 0)
      );
    };

    const updateAvailability = () => {
      let { valid, payload } = validateAvailabilityParams();

      let deletionPayload = prepareDeletionPayload();
      errorMessage.value = "";

      if (valid) {
        isDisabled.value = true;
        payload.push(...deletionPayload.payload);
        CreateAvailability({
          availibilityParams: payload,
        });

        onDone((response) => {
          closeModal();
          isDisabled.value = false;
          const errors = response.data.createAvailibility.errors;

          if (errors?.length > 0) {
            errorMessage.value = errors.join(".");
          } else {
            deleteAvailabilities.value = [];
            availabilitySchedule.value =
              response.data.createAvailibility.availibilities;
            setModifiedAvailibites(
              response.data.createAvailibility.availibilities
            );
          }
        });

        onError((mutationError: ApolloError) => {
          isDisabled.value = false;
          errorMessage.value = mutationError.message;
          console.error("Profile update failed:", mutationError.message);
          console.error("GraphQL Errors:", mutationError.graphQLErrors);
          console.error("Network Error:", mutationError.networkError);
        });
      }
    };

    const prepareDeletionPayload = () => {
      let payload: DeletePayload[] = [];

      for (const entry of deleteAvailabilities.value) {
        if (entry.startTime !== "00:00") {
          const id = entry.id;

          payload.push({
            id: id,
            _destroy: true,
          });
        }
      }
      return { payload };
    };

    const setModifiedAvailibites = (modifiedAvailibites: any) => {
      const newSchedule: WeekSchedule = {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      };

      modifiedAvailibites.forEach((entry: AvailabilityNode) => {
        const { day, startTime, endTime, id, note } = entry;

        const daySchedule: Availability = {
          id,
          startTime: convertTo12HourFormat(startTime),
          endTime: convertTo12HourFormat(endTime),
          note,
          destroy: false,
        };

        if (newSchedule[day as keyof WeekSchedule]) {
          newSchedule[day as keyof WeekSchedule].push(daySchedule);
        }
      });

      availabilitySchedule.value = newSchedule;
      emit("schedule", availabilitySchedule.value);
    };

    const convertTo12HourFormat = (time24: string): string => {
      const [hours, minutes] = time24.split(":").map(Number);
      const ampm = hours >= 12 ? "PM" : "AM";
      const hours12 = hours % 12 || 12;

      const hoursFormatted = hours12.toString().padStart(2, "0");
      const minutesFormatted = minutes.toString().padStart(2, "0");

      return `${hoursFormatted}:${minutesFormatted} ${ampm}`;
    };

    watch(
      () => availabilitiesResult.value,
      (newValue) => {
        if (newValue?.listAvailibility?.edges) {
          const newSchedule: WeekSchedule = {
            Monday: [],
            Tuesday: [],
            Wednesday: [],
            Thursday: [],
            Friday: [],
            Saturday: [],
            Sunday: [],
          };

          newValue.listAvailibility.edges.forEach((edge, index) => {
            const { id, startTime, endTime, day, note } = edge.node;
            if (day in newSchedule) {
              newSchedule[day as keyof WeekSchedule].push({
                id,
                startTime: convertTo12HourFormat(startTime),
                endTime: convertTo12HourFormat(endTime),
                note: note,
                destroy: false,
              });

              if (index == 0) {
                const selectedOption = timezoneOptions.value.find(
                  (option) => option.value === edge.node.timezone
                );
                if (selectedOption) {
                  selectedTimezone.value = selectedOption;
                } else {
                  console.error("Option not found");
                }
              }
            }
          });

          availabilitySchedule.value = newSchedule;
          emit("schedule", newSchedule);
        }
      },
      { immediate: true }
    );

    watch(
      () => availabilitiesError.value,
      (queryError) => {
        if (queryError) {
          errorMessage.value =
            "Failed to fetch necessary data, please refresh the page to try again.";
          console.error("Profile update failed:", queryError.message);
          console.error("GraphQL Errors:", queryError.graphQLErrors);
          console.error("Network Error:", queryError.networkError);
        }
      },
      { immediate: true }
    );

    const calendarUpdateButtonText = computed(() => {
      if (isDisabled.value) {
        return "Updating...";
      } else {
        return "Update";
      }
    });

    return {
      closeModal,
      errorMessage,
      HOME_ICON,
      MUSICAL_NOTES_ICON,
      TRIANGLE_ERROR_ICON,
      CLOSE_ICON,
      calendarUpdateButtonText,
      isDisabled,
      availabilitySchedule,
      updateAvailability,
      handleScheduleUpdate,
      handleMarkedForDeletion,
      selectedTimezone,
      timezoneOptions,
    };
  },
});
</script>
