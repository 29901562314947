<template>
  <div
    v-if="showAddProgramModal"
    class="fixed inset-0 flex items-center justify-center z-50"
  >
    <div class="fixed inset-0 bg-gray-900 opacity-50"></div>

    <div
      class="relative bg-white p-6 rounded-lg shadow-lg z-10 w-full max-w-4xl h-[80vh] flex flex-col"
    >
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-lg font-semibold">Add Software</h2>
        <button @click="closeModal" class="text-gray-500 hover:text-gray-700">
          <div v-html="CLOSE_ICON"></div>
        </button>
      </div>

      <div
        v-if="joinProgramErrorMessage"
        role="alert"
        class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-h-20 overflow-auto"
      >
        <div
          class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
        >
          <img
            src="../../assets/shared/failure.svg"
            alt="Error Icon"
            class="w-4 h-4"
          />
        </div>
        <p>{{ joinProgramErrorMessage }}</p>
      </div>

      <div class="flex flex-col flex-1 overflow-auto">
        <div class="relative">
          <input
            v-model="searchQuery"
            type="text"
            placeholder="Search for software..."
            @focus="isFocused = true"
            @blur="handleBlur"
            class="overflow-hidden px-3.5 py-2.5 w-full text-sm leading-none bg-white rounded-md border border-gray-300 shadow-sm"
          />

          <ul
            v-if="isFocused && searchQuery.length > 0 && !isLoading"
            class="absolute top-full left-0 bg-white border border-gray-300 rounded-md shadow-sm z-50 max-h-60 overflow-auto w-full"
          >
            <li
              v-for="(card, index) in limitedResults"
              :key="index"
              @click="selectProgram(card)"
              class="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100 text-left"
            >
              <img
                :src="programLogo(card)"
                alt="Logo"
                class="w-8 h-8 mr-2 rounded-full"
              />
              {{ card.name }}
            </li>
            <li
              v-if="limitedResults.length === 0 && !isLoading"
              class="px-4 py-2 text-gray-500"
            >
              <div class="flex-1 mb-1">
                <p class="text-left">
                  Software you are looking for is not found
                </p>
              </div>
              <div class="flex justify-center">
                <BaseButton
                  type="button"
                  @click="showCreateSoftwareModal = true"
                  class="border border-gray-300 px-4 py-2 text-base text-gray-700 rounded-md"
                >
                  + Add New Software
                </BaseButton>
              </div>
            </li>
          </ul>
          <AddProgramModal
            :isVisible="showCreateSoftwareModal"
            @update:isVisible="showCreateSoftwareModal = $event"
            @programAdded="handleProgramCreated"
          />
        </div>

        <div class="flex flex-col flex-1">
          <div
            v-if="
              programAdded &&
              (!limitedResults ||
                !searchQuery ||
                !isFocused ||
                (limitedResults.length === 0 && !programAdded))
            "
            class="flex flex-wrap gap-4 px-2 pt-2 pb-8 w-full text-xs leading-none text-gray-500 bg-gray-50 rounded-md min-h-12 overflow-auto"
          >
            <div>
              <SoftwareCard
                :id="programAdded?.id"
                :name="programAdded?.name"
                :experts="programAdded?.experts"
                :logo="programLogo(programAdded)"
                isAdded
                @remove-card="handleRemoveCard"
              />
            </div>
          </div>

          <div class="flex flex-col flex-grow items-center justify-center my-4">
            <h2 class="text-base font-medium text-gray-900 text-center">
              Suggested Softwares:
            </h2>
            <div
              class="flex flex-wrap gap-4 mt-5 w-full max-w-full text-sm leading-none justify-center"
            >
              <div class="flex flex-wrap gap-4 w-full justify-center">
                <SoftwareCard
                  v-for="card in topCards"
                  :key="card.id"
                  :id="card.id"
                  :name="card.name"
                  :experts="card.experts"
                  :logo="programLogo(card)"
                  @add-card="selectProgram(card)"
                  class="flex-shrink-0 w-full sm:w-1/2 md:w-1/2 lg:w-1/3"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex justify-center p-4 mt-auto">
        <BaseButton
          class="bg-violet-600 text-white p-3 border min-w-[30%] rounded-md text-base font-medium"
          type="button"
          @click="handleJoinProgram"
        >
          Add Program
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { CLOSE_ICON } from "@/assets/svg/shared/svgConstants";
import { ApolloError, gql } from "@apollo/client/core";
import { computed, defineComponent, ref, watch } from "vue";
import { Program } from "@/types/dashboard/types";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { useExpertProgramsStore } from "@/stores/expertPrograms";
import { debounce } from "lodash";
import BaseButton from "@/components/shared/BaseButton.vue";
import AddProgramModal from "@/components/onboarding/AddProgramModal.vue";
import SoftwareCard from "../onboarding/SoftwareCard.vue";

const GET_PROGRAM = gql`
  query GetProgram($userBased: Boolean!) {
    getProgram(userBased: $userBased) {
      id
      name
      website
      logo
      experts
      payout
      description
      companyId
    }
  }
`;

const CREATE_PROGRAM_MUTATION = gql`
  mutation CreateProgram($input: CreateProgramInput!) {
    createProgram(input: $input) {
      programs {
        id
        name
        website
        logo
      }
      errors
    }
  }
`;

export default defineComponent({
  name: "JoinProgramModal",
  components: {
    BaseButton,
    AddProgramModal,
    SoftwareCard,
  },
  props: {
    showAddProgramModal: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const joinProgramErrorMessage = ref("");
    const programsList = ref<Program[]>([]);
    const programsStore = useExpertProgramsStore();
    const programAdded = ref<Program | null>(null);
    const searchQuery = ref("");
    const isFocused = ref(false);
    const isLoading = ref(false);
    const limit = 10;
    const filteredNames = ref<Program[]>([]);
    const showCreateSoftwareModal = ref(false);

    const {
      mutate: createProgram,
      onDone: createProgramDone,
      onError: createProgramError,
    } = useMutation(CREATE_PROGRAM_MUTATION);

    const closeModal = () => {
      joinProgramErrorMessage.value = "";
      emit("update:showAddProgramModal", false);
    };

    const fetchProgramData = () => {
      const {
        result: queryResult,
        error: queryError,
        refetch: refetchPrograms,
      } = useQuery(GET_PROGRAM, {
        userBased: false,
      });

      refetchPrograms();

      watch(
        () => queryResult.value,
        (newValue) => {
          if (newValue) {
            programsList.value = newValue.getProgram;
          }
        },
        { immediate: true }
      );

      watch(
        () => queryError.value,
        (newError) => {
          if (newError) {
            joinProgramErrorMessage.value =
              "Failed to fetch programs list, please refresh to try again.";
          }
        }
      );
    };

    const programLogo = (program: Program) => {
      return program.logo ? program.logo : logoFromWebsite(program.website);
    };

    const logoFromWebsite = (websiteUrl: string): string => {
      const urlTemplate =
        "https://img.logo.dev/{domain}?token=pk_R1LW6BZBRPKs6t3nZphCOw";
      const placeholder = "{domain}";

      try {
        const url = new URL(websiteUrl);
        const domain = url.hostname.split(".").slice(-2).join(".");

        return urlTemplate.replace(placeholder, domain);
      } catch (error) {
        return urlTemplate.replace(placeholder, "www.logo.com");
      }
    };

    watch(
      () => props.showAddProgramModal,
      (newValue) => {
        if (newValue) {
          fetchProgramData();
        }
      }
    );

    const handleJoinProgram = () => {
      joinProgramErrorMessage.value = "";
      if (programAdded.value?.id) {
        const addProgram = {
          id: programAdded.value.id,
          name: programAdded.value.name,
          website: programAdded.value.website,
          destroy: false,
        };
        createProgram({
          input: {
            programParams: [addProgram],
            bulk: false,
            joinSingleProgram: true,
          },
        });
      } else {
        joinProgramErrorMessage.value = "Please select a program";
      }
    };

    createProgramDone((response) => {
      if (response) {
        const errors = response.data.createProgram.errors;
        if (errors.length > 0) {
          joinProgramErrorMessage.value = errors.join(". ");
        } else {
          if (programAdded.value) {
            programsStore.addProgram(programAdded.value);
            programsStore.setSelectedProgram(programAdded.value);
            programAdded.value = null;
            closeModal();
          }
        }
      }
    });

    createProgramError((mutationError: ApolloError) => {
      joinProgramErrorMessage.value = "Failed to add program";
      console.log("Add program failed", mutationError);
      scrollToTop();
    });

    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    const selectProgram = (program: Program) => {
      joinProgramErrorMessage.value = "";
      if (
        !programsStore.programs.some(
          (addedProgram) => addedProgram.name === program.name
        )
      ) {
        programAdded.value = program;
        searchQuery.value = "";
      } else {
        joinProgramErrorMessage.value = "This software is already added";
      }
    };

    const handleRemoveCard = () => {
      programAdded.value = null;
      joinProgramErrorMessage.value = "";
    };

    const handleBlur = () => {
      setTimeout(() => {
        isFocused.value = false;
      }, 300);
    };

    const handleProgramCreated = (newProgram: Program) => {
      searchQuery.value = "";
      selectProgram(newProgram);
      programsList.value = [...programsList.value, newProgram];
    };

    const performSearch = debounce(() => {
      filteredNames.value = programsList.value
        .filter((card) =>
          card.name.toLowerCase().includes(searchQuery.value.toLowerCase())
        )
        .slice(0, limit);
      isLoading.value = false;
    }, 100);

    const topCards = computed(() => {
      return [...programsList.value]
        .sort((a, b) => b.experts - a.experts)
        .slice(0, 6);
    });

    watch(searchQuery, () => {
      isLoading.value = true;
      performSearch();
    });

    return {
      CLOSE_ICON,
      closeModal,
      joinProgramErrorMessage,
      topCards,
      programLogo,
      selectProgram,
      handleJoinProgram,
      programAdded,
      handleRemoveCard,
      searchQuery,
      filteredNames,
      limitedResults: filteredNames,
      isFocused,
      handleBlur,
      isLoading,
      performSearch,
      showCreateSoftwareModal,
      handleProgramCreated,
    };
  },
});
</script>
