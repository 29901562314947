import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-da1e27a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", {
    for: _ctx.forAttr,
    class: _normalizeClass(_ctx.labelClasses)
  }, _toDisplayString(_ctx.text), 11, _hoisted_1))
}