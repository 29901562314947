export const COMPANY_LOGO = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="97"
    height="24"
    viewBox="0 0 97 24"
    fill="none"
  >
    <path
      d="M87.2116 15.3128C87.7038 15.5847 88.2894 15.8566 88.9683 16.1286C89.6473 16.3835 90.462 16.511 91.4125 16.511C91.735 16.511 92.049 16.494 92.3545 16.46C92.66 16.409 92.9231 16.341 93.1437 16.256C93.3814 16.154 93.5681 16.0266 93.7038 15.8736C93.8396 15.7037 93.9075 15.5082 93.9075 15.2873C93.9075 14.9643 93.7463 14.6839 93.4238 14.446C93.1183 14.191 92.5836 13.9361 91.8198 13.6812L90.8778 13.4007C90.3347 13.2478 89.834 13.0693 89.3757 12.8654C88.9344 12.6444 88.544 12.381 88.2046 12.0751C87.8821 11.7692 87.6275 11.4122 87.4408 11.0043C87.2541 10.5964 87.1607 10.1121 87.1607 9.55121C87.1607 8.90538 87.271 8.36151 87.4917 7.91962C87.7293 7.46074 88.0433 7.09533 88.4337 6.8234C88.841 6.55147 89.3163 6.35602 89.8594 6.23705C90.4026 6.11808 90.9797 6.05859 91.5907 6.05859C92.3545 6.05859 93.1437 6.15207 93.9584 6.33902C94.7732 6.50898 95.52 6.79791 96.1989 7.2058L95.9188 9.72967C95.7152 9.5937 95.4351 9.44924 95.0787 9.29628C94.7392 9.12632 94.3573 8.97336 93.933 8.83739C93.5087 8.70143 93.0758 8.59096 92.6345 8.50598C92.1932 8.404 91.7774 8.35301 91.387 8.35301C90.9457 8.35301 90.5723 8.43799 90.2668 8.60795C89.9782 8.77791 89.834 9.06684 89.834 9.47473C89.834 9.88263 90.0037 10.2225 90.3432 10.4945C90.6826 10.7664 91.2088 11.0298 91.9217 11.2848L92.8128 11.5652C93.305 11.7182 93.7717 11.8796 94.213 12.0496C94.6713 12.2195 95.0702 12.4405 95.4096 12.7124C95.7661 12.9843 96.0461 13.3158 96.2498 13.7067C96.4705 14.0806 96.5808 14.5649 96.5808 15.1598C96.5808 15.8396 96.4365 16.4175 96.148 16.8934C95.8764 17.3522 95.503 17.7262 95.0278 18.0151C94.5525 18.304 93.9924 18.508 93.3474 18.6269C92.7194 18.7629 92.0405 18.8309 91.3106 18.8309C90.3601 18.8309 89.5454 18.7374 88.8665 18.5504C88.1876 18.3635 87.5511 18.1256 86.957 17.8366L87.2116 15.3128Z"
      fill="#2C2C2C"
    />
    <path
      d="M78.624 6.27157V3.32618L81.2996 2.79297V6.27157H85.1475V8.55678H81.3506V16.1995H85.7081V18.5355H81.8093C81.3506 18.5355 80.9259 18.4763 80.5351 18.3578C80.1444 18.2224 79.8046 18.0277 79.5158 17.7738C79.227 17.5199 78.9977 17.1983 78.8278 16.8089C78.6749 16.4196 78.5985 15.9626 78.5985 15.4378V8.55678H76.2031V6.27157H78.624Z"
      fill="#2C2C2C"
    />
    <path
      d="M70.1848 6.25923V8.16535C70.457 7.57234 70.8738 7.07251 71.4351 6.66587C71.9964 6.25923 72.6513 6.03897 73.3998 6.00508H73.706C73.9951 5.98814 74.2673 6.01355 74.5225 6.08133C74.7946 6.13216 75.0583 6.19993 75.3134 6.28465V8.72448C75.0243 8.58894 74.7181 8.48728 74.3949 8.4195C74.0887 8.35173 73.774 8.31784 73.4508 8.31784C73.0426 8.31784 72.6428 8.37715 72.2516 8.49575C71.8774 8.59741 71.5372 8.75837 71.231 8.97863C70.9248 9.18195 70.6781 9.45304 70.491 9.79191C70.3039 10.1138 70.2104 10.5035 70.2104 10.961V18.5347H67.5312V6.25923H70.1848Z"
      fill="#2C2C2C"
    />
    <path
      d="M54.5393 12.9113C54.5901 13.4195 54.7171 13.8938 54.9202 14.3342C55.1234 14.7747 55.3942 15.1558 55.7328 15.4777C56.0883 15.7826 56.5031 16.0282 56.9771 16.2145C57.4511 16.4009 57.9928 16.494 58.6022 16.494C59.5164 16.494 60.2697 16.3077 60.8622 15.935C61.4547 15.5454 61.9203 15.0626 62.2588 14.4867L64.4934 15.5539C63.9348 16.5364 63.1645 17.3325 62.1827 17.9424C61.2008 18.5352 59.9819 18.8317 58.5261 18.8317C57.5442 18.8317 56.6385 18.6708 55.809 18.3489C54.9964 18.0271 54.2939 17.5866 53.7014 17.0276C53.1088 16.4517 52.6518 15.7741 52.3301 14.9949C52.0085 14.2157 51.8477 13.3602 51.8477 12.4285C51.8477 11.4799 52.0085 10.616 52.3301 9.8368C52.6687 9.04064 53.1258 8.36306 53.7014 7.80406C54.2939 7.22811 54.9879 6.78769 55.7836 6.48278C56.5792 6.16093 57.4426 6 58.3737 6C59.2878 6 60.1343 6.15246 60.913 6.45737C61.7087 6.76228 62.3858 7.19424 62.9445 7.75324C63.52 8.31224 63.9686 8.98135 64.2903 9.76057C64.6289 10.5398 64.7982 11.3952 64.7982 12.3269V12.9113H54.5393ZM61.9287 11.0056C61.7087 10.2095 61.2939 9.5573 60.6845 9.04911C60.075 8.54093 59.3048 8.28683 58.3737 8.28683C57.4595 8.28683 56.6808 8.54093 56.0375 9.04911C55.3942 9.54036 54.9541 10.1925 54.7171 11.0056H61.9287Z"
      fill="#2C2C2C"
    />
    <path
      d="M36.877 24V6.2546H39.4789V8.95332C39.683 8.47808 39.9466 8.06223 40.2697 7.7058C40.6099 7.33239 40.9925 7.01839 41.4177 6.76379C41.8598 6.50919 42.3275 6.32249 42.8207 6.20368C43.3139 6.06789 43.8156 6 44.3258 6C45.1081 6 45.8393 6.15276 46.5196 6.45827C47.1998 6.76379 47.7866 7.19661 48.2798 7.75672C48.7729 8.31683 49.1641 8.98727 49.4532 9.76803C49.7423 10.5318 49.8869 11.3805 49.8869 12.314C49.8869 13.2475 49.7423 14.1132 49.4532 14.9109C49.1641 15.7086 48.7559 16.396 48.2287 16.9731C47.7015 17.5502 47.0723 18 46.341 18.3225C45.6097 18.645 44.8019 18.8062 43.9176 18.8062C43.4584 18.8062 43.0078 18.7298 42.5656 18.5771C42.1234 18.4413 41.7153 18.2546 41.3411 18.017C40.967 17.7793 40.6269 17.4993 40.3207 17.1768C40.0146 16.8373 39.7595 16.4809 39.5555 16.1075V24H36.877ZM43.3309 16.4894C43.9091 16.4894 44.4278 16.3791 44.887 16.1584C45.3631 15.9378 45.7628 15.6492 46.0859 15.2928C46.4261 14.9194 46.6811 14.4866 46.8512 13.9943C47.0383 13.5021 47.1318 12.9844 47.1318 12.4413C47.1318 11.8812 47.0383 11.355 46.8512 10.8628C46.6811 10.3706 46.4261 9.94625 46.0859 9.58982C45.7628 9.21641 45.3717 8.92786 44.9125 8.72419C44.4533 8.50354 43.9346 8.39321 43.3564 8.39321C42.7782 8.39321 42.251 8.50354 41.7748 8.72419C41.2986 8.92786 40.8905 9.21641 40.5503 9.58982C40.2272 9.96322 39.9721 10.396 39.785 10.8883C39.615 11.3805 39.53 11.8982 39.53 12.4413C39.53 12.9844 39.615 13.5021 39.785 13.9943C39.9721 14.4866 40.2272 14.9194 40.5503 15.2928C40.8905 15.6492 41.2901 15.9378 41.7493 16.1584C42.2255 16.3791 42.7527 16.4894 43.3309 16.4894Z"
      fill="#2C2C2C"
    />
    <path
      d="M27.0086 12.2722L22.3811 6.23828H25.4576L28.5596 10.2864L31.6615 6.23828H34.738L30.0597 12.2722L34.916 18.5353H31.7886L28.5596 14.2581L25.2796 18.5353H22.2031L27.0086 12.2722Z"
      fill="#2C2C2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.575 2.375H14.0232V3.14669L14.575 2.375ZM16.2381 4.84719L18.0058 2.375H22.0791V4.84719H16.2381ZM10.4355 12.9621L13.2891 8.97137V8.97599H20.9581V11.2698H13.2891V16.0612H21.9262V18.5334H10.4355V12.9621Z"
      fill="#2C2C2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.6154 0L0 18.5133H3.05775L17.095 0H13.6154Z"
      fill="#6A48F3"
    />
  </svg>
  `;

export const CLOSE_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-6 w-6"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
`;

export const CLOSE_ICON_SM = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-4 w-4"
    fill="none"
    viewBox="0 0 20 20"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
`;

export const TRIANGLE_ERROR_ICON = `
  <svg xmlns="http://www.w3.org/2000/svg" class="inline-block w-4 h-4 fill-current text-red-600 mr-1" viewBox="0 0 24 24">
    <path d="M12 2c-.69 0-1.36.33-1.76.92L1.92 18.08C1.33 19.05 2.07 20.25 3.16 20.25h17.68c1.09 0 1.83-1.2 1.24-2.17L13.76 2.92A2.01 2.01 0 0012 2zM12 17.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm1-4.5h-2v-6h2v6z"/>
  </svg>
`;

export const ADD_IMAGE_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      d="M28 8H12C10.9391 8 9.92172 8.42143 9.17157 9.17157C8.42143 9.92172 8 10.9391 8 12V32M8 32V36C8 37.0609 8.42143 38.0783 9.17157 38.8284C9.92172 39.5786 10.9391 40 12 40H36C37.0609 40 38.0783 39.5786 38.8284 38.8284C39.5786 38.0783 40 37.0609 40 36V28M8 32L17.172 22.828C17.9221 22.0781 18.9393 21.6569 20 21.6569C21.0607 21.6569 22.0779 22.0781 22.828 22.828L28 28M40 20V28M40 28L36.828 24.828C36.0779 24.0781 35.0607 23.6569 34 23.6569C32.9393 23.6569 31.9221 24.0781 31.172 24.828L28 28M28 28L32 32M36 8H44M40 4V12M28 16H28.02"
      stroke="#9CA3AF"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`;

export const CIRCLE_PLUS = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    class="currentColor"
  >
    <path
      d="M12 9V12M12 12V15M12 12H15M12 12H9M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`;

export const CHECK_MARK = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    class="size-6"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="m4.5 12.75 6 6 9-13.5"
    />
  </svg>
`;

export const HOME_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M3 12L5 10M5 10L12 3L19 10M5 10V20C5 20.5523 5.44772 21 6 21H9M19 10L21 12M19 10V20C19 20.5523 18.5523 21 18 21H15M9 21C9.55228 21 10 20.5523 10 20V16C10 15.4477 10.4477 15 11 15H13C13.5523 15 14 15.4477 14 16V20C14 20.5523 14.4477 21 15 21M9 21H15"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`;

export const MUSICAL_NOTES_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9 19V6L21 3V16M9 19C9 20.1046 7.65685 21 6 21C4.34315 21 3 20.1046 3 19C3 17.8954 4.34315 17 6 17C7.65685 17 9 17.8954 9 19ZM21 16C21 17.1046 19.6569 18 18 18C16.3431 18 15 17.1046 15 16C15 14.8954 16.3431 14 18 14C19.6569 14 21 14.8954 21 16ZM9 10L21 7"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`;
