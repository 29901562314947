export const industries: string[] = [
  "Linkedin Industry Label",
  "Accommodation Services",
  "Food and Beverage Services",
  "Bars, Taverns, and Nightclubs",
  "Caterers",
  "Mobile Food Services",
  "Restaurants",
  "Hospitality",
  "Bed-and-Breakfasts, Hostels, Homestays",
  "Hotels and Motels",
  "Administrative and Support Services",
  "Collection Agencies",
  "Events Services",
  "Facilities Services",
  "Janitorial Services",
  "Landscaping Services",
  "Fundraising",
  "Office Administration",
  "Security and Investigations",
  "Security Guards and Patrol Services",
  "Security Systems Services",
  "Staffing and Recruiting",
  "Executive Search Services",
  "Temporary Help Services",
  "Telephone Call Centers",
  "Translation and Localization",
  "Travel Arrangements",
  "Writing and Editing",
  "Construction",
  "Building Construction",
  "Nonresidential Building Construction",
  "Residential Building Construction",
  "Civil Engineering",
  "Highway, Street, and Bridge Construction",
  "Subdivision of Land",
  "Utility System Construction",
  "Specialty Trade Contractors",
  "Building Equipment Contractors",
  "Building Finishing Contractors",
  "Building Structure and Exterior Contractors",
  "Consumer Services",
  "Civic and Social Organizations",
  "Industry Associations",
  "Political Organizations",
  "Professional Organizations",
  "Household Services",
  "Non-profit Organizations",
  "Personal and Laundry Services",
  "Laundry and Drycleaning Services",
  "Personal Care Services",
  "Pet Services",
  "Philanthropic Fundraising Services",
  "Religious Institutions",
  "Repair and Maintenance",
  "Commercial and Industrial Machinery Maintenance",
  "Electronic and Precision Equipment Maintenance",
  "Footwear and Leather Goods Repair",
  "Reupholstery and Furniture Repair",
  "Vehicle Repair and Maintenance",
  "Education",
  "E-Learning Providers",
  "Higher Education",
  "Primary and Secondary Education",
  "Professional Training and Coaching",
  "Technical and Vocational Training",
  "Cosmetology and Barber Schools",
  "Fine Arts Schools",
  "Flight Training",
  "Language Schools",
  "Secretarial Schools",
  "Sports and Recreation Instruction",
  "Entertainment Providers",
  "Artists and Writers",
  "Museums, Historical Sites, and Zoos",
  "Historical Sites",
  "Museums",
  "Zoos and Botanical Gardens",
  "Musicians",
  "Performing Arts and Spectator Sports",
  "Circuses and Magic Shows",
  "Dance Companies",
  "Performing Arts",
  "Spectator Sports",
  "Racetracks",
  "Sports Teams and Clubs",
  "Theater Companies",
  "Recreational Facilities",
  "Amusement Parks and Arcades",
  "Gambling Facilities and Casinos",
  "Golf Courses and Country Clubs",
  "Skiing Facilities",
  "Wellness and Fitness Services",
  "Farming, Ranching, Forestry",
  "Farming",
  "Horticulture",
  "Forestry and Logging",
  "Ranching and Fisheries",
  "Fisheries",
  "Ranching",
  "Financial Services",
  "Capital Markets",
  "Investment Advice",
  "Investment Banking",
  "Investment Management",
  "Securities and Commodity Exchanges",
  "Venture Capital and Private Equity Principals",
  "Credit Intermediation",
  "Banking",
  "International Trade and Development",
  "Loan Brokers",
  "Savings Institutions",
  "Funds and Trusts",
  "Insurance and Employee Benefit Funds",
  "Pension Funds",
  "Trusts and Estates",
  "Insurance",
  "Claims Adjusting, Actuarial Services",
  "Insurance Agencies and Brokerages",
  "Insurance Carriers",
  "Government Administration",
  "Administration of Justice",
  "Correctional Institutions",
  "Courts of Law",
  "Fire Protection",
  "Law Enforcement",
  "Public Safety",
  "Economic Programs",
  "Transportation Programs",
  "Utilities Administration",
  "Environmental Quality Programs",
  "Air, Water, and Waste Program Management",
  "Conservation Programs",
  "Health and Human Services",
  "Education Administration Programs",
  "Public Assistance Programs",
  "Public Health",
  "Housing and Community Development",
  "Community Development and Urban Planning",
  "Housing Programs",
  "Military and International Affairs",
  "Armed Forces",
  "International Affairs",
  "Public Policy Offices",
  "Executive Offices",
  "Legislative Offices",
  "Space Research and Technology",
  "Holding Companies",
  "Hospitals and Health Care",
  "Community Services",
  "Services for the Elderly and Disabled",
  "Hospitals",
  "Individual and Family Services",
  "Child Day Care Services",
  "Emergency and Relief Services",
  "Vocational Rehabilitation Services",
  "Medical Practices",
  "Alternative Medicine",
  "Ambulance Services",
  "Chiropractors",
  "Dentists",
  "Family Planning Centers",
  "Home Health Care Services",
  "Medical and Diagnostic Laboratories",
  "Mental Health Care",
  "Optometrists",
  "Outpatient Care Centers",
  "Physical, Occupational and Speech Therapists",
  "Physicians",
  "Nursing Homes and Residential Care Facilities",
  "Manufacturing",
  "Apparel Manufacturing",
  "Fashion Accessories Manufacturing",
  "Appliances, Electrical, and Electronics Manufacturing",
  "Electric Lighting Equipment Manufacturing",
  "Electrical Equipment Manufacturing",
  "Household Appliance Manufacturing",
  "Chemical Manufacturing",
  "Agricultural Chemical Manufacturing",
  "Artificial Rubber and Synthetic Fiber Manufacturing",
  "Chemical Raw Materials Manufacturing",
  "Paint, Coating, and Adhesive Manufacturing",
  "Personal Care Product Manufacturing",
  "Pharmaceutical Manufacturing",
  "Soap and Cleaning Product Manufacturing",
  "Computers and Electronics Manufacturing",
  "Audio and Video Equipment Manufacturing",
  "Communications Equipment Manufacturing",
  "Computer Hardware Manufacturing",
  "Magnetic and Optical Media Manufacturing",
  "Measuring and Control Instrument Manufacturing",
  "Semiconductor Manufacturing",
  "Renewable Energy Semiconductor Manufacturing",
  "Fabricated Metal Products",
  "Architectural and Structural Metal Manufacturing",
  "Boilers, Tanks, and Shipping Container Manufacturing",
  "Construction Hardware Manufacturing",
  "Cutlery and Handtool Manufacturing",
  "Metal Treatments",
  "Metal Valve, Ball, and Roller Manufacturing",
  "Spring and Wire Product Manufacturing",
  "Turned Products and Fastener Manufacturing",
  "Food and Beverage Manufacturing",
  "Breweries",
  "Wineries",
  "Animal Feed Manufacturing",
  "Baked Goods Manufacturing",
  "Beverage Manufacturing",
  "Dairy Product Manufacturing",
  "Distilleries",
  "Fruit and Vegetable Preserves Manufacturing",
  "Meat Products Manufacturing",
  "Seafood Product Manufacturing",
  "Sugar and Confectionery Product Manufacturing",
  "Furniture and Home Furnishings Manufacturing",
  "Household and Institutional Furniture Manufacturing",
  "Mattress and Blinds Manufacturing",
  "Office Furniture and Fixtures Manufacturing",
  "Glass, Ceramics and Concrete Manufacturing",
  "Abrasives and Nonmetallic Minerals Manufacturing",
  "Clay and Refractory Products Manufacturing",
  "Glass Product Manufacturing",
  "Lime and Gypsum Products Manufacturing",
  "Leather Product Manufacturing",
  "Footwear Manufacturing",
  "Women’s Handbag Manufacturing",
  "Machinery Manufacturing",
  "Agriculture, Construction, Mining Machinery Manufacturing",
  "Automation Machinery Manufacturing",
  "Commercial and Service Industry Machinery Manufacturing",
  "Engines and Power Transmission Equipment Manufacturing",
  "Renewable Energy Equipment Manufacturing",
  "HVAC and Refrigeration Equipment Manufacturing",
  "Industrial Machinery Manufacturing",
  "Metalworking Machinery Manufacturing",
  "Medical Equipment Manufacturing",
  "Oil and Coal Product Manufacturing",
  "Paper and Forest Product Manufacturing",
  "Plastics and Rubber Product Manufacturing",
  "Packaging and Containers Manufacturing",
  "Plastics Manufacturing",
  "Rubber Products Manufacturing",
  "Primary Metal Manufacturing",
  "Printing Services",
  "Sporting Goods Manufacturing",
  "Textile Manufacturing",
  "Tobacco Manufacturing",
  "Transportation Equipment Manufacturing",
  "Aviation and Aerospace Component Manufacturing",
  "Defense and Space Manufacturing",
  "Motor Vehicle Manufacturing",
  "Motor Vehicle Parts Manufacturing",
  "Railroad Equipment Manufacturing",
  "Shipbuilding",
  "Wood Product Manufacturing",
  "Oil, Gas, and Mining",
  "Mining",
  "Coal Mining",
  "Metal Ore Mining",
  "Nonmetallic Mineral Mining",
  "Oil and Gas",
  "Natural Gas Extraction",
  "Oil Extraction",
  "Professional Services",
  "Accounting",
  "Advertising Services",
  "Government Relations Services",
  "Public Relations and Communications Services",
  "Market Research",
  "Architecture and Planning",
  "Business Consulting and Services",
  "Environmental Services",
  "Human Resources Services",
  "Marketing Services",
  "Operations Consulting",
  "Outsourcing and Offshoring Consulting",
  "Strategic Management Services",
  "Design Services",
  "Graphic Design",
  "Interior Design",
  "Engineering Services",
  "IT Services and IT Consulting",
  "Computer and Network Security",
  "IT System Custom Software Development",
  "IT System Data Services",
  "IT System Design Services",
  "IT System Installation and Disposal",
  "IT System Operations and Maintenance",
  "IT System Testing and Evaluation",
  "IT System Training and Support",
  "Legal Services",
  "Alternative Dispute Resolution",
  "Law Practice",
  "Photography",
  "Research Services",
  "Nanotechnology Research",
  "Think Tanks",
  "Services for Renewable Energy",
  "Veterinary Services",
  "Real Estate and Equipment Rental Services",
  "Equipment Rental Services",
  "Commercial and Industrial Equipment Rental",
  "Consumer Goods Rental",
  "Leasing Non-residential Real Estate",
  "Leasing Real Estate Agents and Brokers",
  "Leasing Residential Real Estate",
  "Real Estate",
  "Retail",
  "Food and Beverage Retail",
  "Retail Groceries",
  "Online and Mail Order Retail",
  "Retail Apparel and Fashion",
  "Retail Appliances, Electrical, and Electronic Equipment",
  "Retail Art Dealers",
  "Retail Art Supplies",
  "Retail Books and Printed News",
  "Retail Building Materials and Garden Equipment",
  "Retail Florists",
  "Retail Furniture and Home Furnishings",
  "Retail Gasoline",
  "Retail Health and Personal Care Products",
  "Retail Luxury Goods and Jewelry",
  "Retail Motor Vehicles",
  "Retail Musical Instruments",
  "Retail Office Equipment",
  "Retail Office Supplies and Gifts",
  "Retail Recyclable Materials & Used Merchandise",
  "Technology, Information and Media",
  "Media and Telecommunications",
  "Book and Periodical Publishing",
  "Book Publishing",
  "Newspaper Publishing",
  "Periodical Publishing",
  "Broadcast Media Production and Distribution",
  "Cable and Satellite Programming",
  "Radio and Television Broadcasting",
  "Movies, Videos and Sound",
  "Animation and Post-production",
  "Media Production",
  "Movies and Sound Recording",
  "Sound Recording",
  "Sheet Music Publishing",
  "Telecommunications",
  "Satellite Telecommunications",
  "Telecommunications Carriers",
  "Wireless Services",
  "Technology, Information and Internet",
  "Data Infrastructure and Analytics",
  "Blockchain Services",
  "Business Intelligence Platforms",
  "Information Services",
  "Internet Publishing",
  "Business Content",
  "Online Audio and Video Media",
  "Internet News",
  "Libraries",
  "Blogs",
  "Internet Marketplace Platforms",
  "Social Networking Platforms",
  "Software Development",
  "Computer Games",
  "Mobile Gaming Apps",
  "Computer Networking Products",
  "Desktop Computing Software Products",
  "Embedded Software Products",
  "Mobile Computing Software Products",
  "Data Security Software Products",
  "Transportation, Logistics, Supply Chain and Storage",
  "Airlines and Aviation",
  "Freight and Package Transportation",
  "Ground Passenger Transportation",
  "Interurban and Rural Bus Services",
  "School and Employee Bus Services",
  "Shuttles and Special Needs Transportation Services",
  "Sightseeing Transportation",
  "Taxi and Limousine Services",
  "Urban Transit Services",
  "Maritime Transportation",
  "Pipeline Transportation",
  "Postal Services",
  "Rail Transportation",
  "Truck Transportation",
  "Warehousing and Storage",
  "Utilities",
  "Electric Power Generation",
  "Fossil Fuel Electric Power Generation",
  "Nuclear Electric Power Generation",
  "Renewable Energy Power Generation",
  "Biomass Electric Power Generation",
  "Geothermal Electric Power Generation",
  "Hydroelectric Power Generation",
  "Solar Electric Power Generation",
  "Wind Electric Power Generation",
  "Electric Power Transmission, Control, and Distribution",
  "Natural Gas Distribution",
  "Water, Waste, Steam, and Air Conditioning Services",
  "Steam and Air-Conditioning Supply",
  "Waste Collection",
  "Waste Treatment and Disposal",
  "Water Supply and Irrigation Systems",
  "Wholesale",
  "Wholesale Alcoholic Beverages",
  "Wholesale Apparel and Sewing Supplies",
  "Wholesale Appliances, Electrical, and Electronics",
  "Wholesale Building Materials",
  "Wholesale Chemical and Allied Products",
  "Wholesale Computer Equipment",
  "Wholesale Drugs and Sundries",
  "Wholesale Food and Beverage",
  "Wholesale Footwear",
  "Wholesale Furniture and Home Furnishings",
  "Wholesale Hardware, Plumbing, Heating Equipment",
  "Wholesale Import and Export",
  "Wholesale Luxury Goods and Jewelry",
  "Wholesale Machinery",
  "Wholesale Metals and Minerals",
  "Wholesale Motor Vehicles and Parts",
  "Wholesale Paper Products",
  "Wholesale Petroleum and Petroleum Products",
  "Wholesale Photography Equipment and Supplies",
  "Wholesale Raw Farm Products",
  "Wholesale Recyclable Materials",
];
