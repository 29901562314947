// src/apollo.js
import {
  ApolloClient,
  InMemoryCache,
  // HttpLink,
  ApolloLink,
} from "@apollo/client/core";
// import { createUploadLink } from "apollo-upload-client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

import { setContext } from "@apollo/client/link/context";
import { provideApolloClient } from "@vue/apollo-composable";
import { useAuthStore } from "@/stores/auth";

// const httpLink = new HttpLink({
//   uri: process.env.VUE_APP_API_TARGET || "http://localhost:3000/graphql",
// });

const uploadLink = createUploadLink({
  uri: "https://staging-api.slashexperts.com/graphql",
});

const authLink = setContext((_, { headers }) => {
  const authStore = useAuthStore();
  const token = authStore.token;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const link = ApolloLink.from([authLink, uploadLink]);

const apolloClient = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
});

export function setupApolloClient() {
  provideApolloClient(apolloClient);
}
