<template>
  <div
    class="flex flex-col bg-white rounded-md border border-gray-300 shadow-sm w-full max-w-full min-w-[19rem] max-w-[19rem]"
  >
    <div class="flex items-center h-12 sm:h-14 md:h-16 lg:h-18">
      <!-- Logo image on the left -->
      <div
        class="flex-shrink-0 w-12 h-12 sm:w-14 sm:h-14 md:w-16 md:h-16 lg:w-18 lg:h-18"
      >
        <img :src="logo" alt="Logo" class="object-cover w-full h-full" />
      </div>
      <!-- Text and icon container on the right -->
      <div class="flex flex-col flex-1 px-2 sm:px-4 py-2 sm:py-2.5 space-y-2">
        <!-- Text content -->
        <div
          class="flex flex-row items-start sm:items-center space-y-2 sm:space-x-2 sm:space-y-0"
        >
          <div class="flex-1 text-xs sm:text-sm md:text-base lg:text-lg">
            <div
              class="font-medium text-gray-900 truncate text-left break-words"
            >
              {{ name.length > 15 ? name.slice(0, 18) + "..." : name }}
            </div>
            <div class="text-gray-500 truncate text-left break-words">
              {{ experts }} Experts
            </div>
          </div>
          <!-- Icon as a button -->
          <button
            v-if="!isAdded"
            @click="emitCardData"
            class="flex-shrink-0 text-primary-purple focus:outline-none"
          >
            <div v-html="CIRCLE_PLUS"></div>
          </button>
          <button
            v-else
            class="flex-shrink-0 text-gray-600 focus:outline-none"
            @click="removeCard"
          >
            <div v-html="CLOSE_ICON_SM"></div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { CLOSE_ICON_SM, CIRCLE_PLUS } from "@/assets/svg/shared/svgConstants";

export default defineComponent({
  name: "SoftwareCard",
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    experts: {
      type: Number,
      required: true,
    },
    logo: {
      type: String,
      required: true,
    },
    website: {
      type: String,
      requried: true,
    },
    isAdded: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    emitCardData() {
      this.$emit("add-card", {
        id: this.id,
        name: this.name,
        experts: this.experts,
        logo: this.logo,
        website: this.website,
      });
    },
    removeCard() {
      this.$emit("remove-card", {
        id: this.id,
        name: this.name,
        experts: this.experts,
        logo: this.logo,
        website: this.website,
      });
    },
  },

  setup() {
    return {
      CIRCLE_PLUS,
      CLOSE_ICON_SM,
    };
  },
});
</script>
