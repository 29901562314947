import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "relative",
  ref: "container"
}
const _hoisted_2 = { class: "divide-y divide-gray-200" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args))),
      class: "w-full px-4 py-2 border border-gray-300 mr-2 rounded-md bg-white text-left flex justify-between items-center text-sm"
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.selectedTime), 1),
      _createElementVNode("i", {
        class: _normalizeClass(`fas fa-chevron-${
          _ctx.isDropdownOpen ? 'up' : 'down'
        } text-gray-600`)
      }, null, 2)
    ]),
    (_ctx.isDropdownOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass([_ctx.dropdownClass, "absolute w-full border border-gray-300 bg-white rounded-md shadow-lg z-10 max-h-60 overflow-y-auto text-sm"])
        }, [
          _createElementVNode("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedTimeOptions, (time) => {
              return (_openBlock(), _createElementBlock("li", {
                key: time,
                onClick: ($event: any) => (_ctx.selectTime(time)),
                class: "px-4 py-2 cursor-pointer hover:bg-gray-100"
              }, _toDisplayString(time), 9, _hoisted_3))
            }), 128))
          ])
        ], 2))
      : _createCommentVNode("", true)
  ], 512))
}