<template>
  <button
    :type="type"
    :class="computedClasses"
    :disabled="disabled"
    @click="handleClick"
  >
    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";

export default defineComponent({
  name: "BaseButton",
  props: {
    type: {
      type: String as PropType<"button" | "submit" | "reset">,
      default: "button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
      default: "",
    },
    onClick: {
      type: Function as PropType<(event: MouseEvent) => void>,
      default: () => {
        //
      },
    },
  },
  setup(props) {
    const computedClasses = computed<string>(() => {
      return props.className;
    });

    const handleClick = (event: MouseEvent) => {
      if (!props.disabled) {
        props.onClick(event);
      }
    };

    return {
      computedClasses,
      handleClick,
    };
  },
});
</script>
