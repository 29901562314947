<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50"
  >
    <div class="bg-white p-6 rounded-lg shadow-lg w-80">
      <p class="mb-4">{{ message }}</p>
      <div class="mb-4">
        <div class="bg-gray-300 rounded-full h-4">
          <div
            class="bg-primary-purple h-4 rounded-full"
            :style="{ width: progress + '%' }"
          ></div>
        </div>
        <p class="text-sm text-gray-600 mt-2">{{ progress }}% complete</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "SubmitExpertiseLoader",
  props: {
    isVisible: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    progress: {
      type: Number as PropType<number>,
      required: true,
    },
    message: {
      type: String as PropType<string>,
      required: true,
    },
  },
});
</script>

<style scoped>
/* Add any additional styling or animations here */
</style>
