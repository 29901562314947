import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex flex-wrap gap-3 mt-2" }
const _hoisted_2 = { class: "flex gap-1.5 mt-3 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PasswordStrengthIndicator = _resolveComponent("PasswordStrengthIndicator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PasswordStrengthIndicator, {
        "is-valid": _ctx.hasUpperCase,
        text: "Upper case letter"
      }, null, 8, ["is-valid"]),
      _createVNode(_component_PasswordStrengthIndicator, {
        "is-valid": _ctx.hasLowerCase,
        text: "Lower case letter"
      }, null, 8, ["is-valid"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PasswordStrengthIndicator, {
        "is-valid": _ctx.hasMinLength,
        text: "Min 8 character"
      }, null, 8, ["is-valid"])
    ])
  ]))
}