<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
  >
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
      <h3 class="text-lg font-semibold mb-4">Add Note For</h3>
      <p class="mb-2">Day: {{ day }}</p>
      <p class="mb-2">Start Time: {{ startTime }}</p>
      <p class="mb-4">End Time: {{ endTime }}</p>
      <textarea
        v-model="note"
        rows="4"
        class="w-full border rounded px-2 py-1 mb-4"
        placeholder="Enter your note here..."
      ></textarea>
      <div class="flex flex-col gap-4">
        <button
          class="bg-violet-600 text-white px-4 py-2 rounded"
          @click="save"
        >
          Save
        </button>
        <button
          class="text-indigo-700 bg-indigo-50 px-4 py-2 rounded"
          @click="close"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "AvailabilityNoteModal",
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    day: {
      type: String,
      required: true,
      default: "",
    },
    startTime: {
      type: String,
      required: true,
      default: "",
    },
    endTime: {
      type: String,
      required: true,
      default: "",
    },
    initialNote: {
      type: String,
      required: false,
      default: "",
    },
  },
  emits: ["close", "save"],
  setup(props, { emit }) {
    const note = ref(props.initialNote);

    watch(
      () => props.initialNote,
      (newNote) => {
        note.value = newNote;
      }
    );

    const close = () => {
      note.value = "";
      emit("close");
    };

    const save = () => {
      emit("save", note.value);
    };

    return {
      note,
      close,
      save,
    };
  },
});
</script>

<style scoped>
/* Add any custom styles for the modal here */
</style>
