<template>
  <div class="flex gap-1.5 items-center">
    <div
      class="relative flex justify-center items-center p-2.5 my-auto w-6 h-6 rounded-full"
      :class="{
        'bg-gray-100': !isValid,
        'bg-green-100': isValid,
      }"
    >
      <i
        class="fas fa-check"
        :class="{
          'text-gray-300': !isValid,
          'text-green-300': isValid,
        }"
        style="
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        "
      ></i>
    </div>
    <div class="text-sm tracking-wide text-gray-500">{{ text }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PasswordStrengthIndicator",
  props: {
    isValid: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
});
</script>
