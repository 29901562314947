import { App } from "vue";
import posthog, { PostHog } from "posthog-js";

export default {
  install(app: App) {
    const posthogInstance = posthog.init(
      "phc_6CGAIoA7NfXCuvZzayZNktQ9sG8DVOTM4UruDSmR9eC",
      {
        api_host: "https://us.i.posthog.com",
        person_profiles: "identified_only",
      }
    ) as PostHog;

    if (posthogInstance) {
      app.config.globalProperties.$posthog = posthogInstance;
    } else {
      console.error("PostHog initialization failed.");
    }
  },
};
