<template>
  <main class="flex flex-col min-h-screen bg-gray-50">
    <section
      class="flex-grow relative flex flex-col justify-center items-center px-16 py-20 w-full max-md:px-5 max-md:max-w-full"
    >
      <img
        loading="lazy"
        src="../assets/shared/background.png"
        alt=""
        class="absolute inset-0 w-full h-full object-cover"
      />
      <div class="flex relative flex-col mb-12 max-w-full w-1/4 max-md:mb-10">
        <img
          loading="lazy"
          src="../assets/shared/experts-text.png"
          alt="Company logo"
          class="self-center max-w-full aspect-[4]"
        />
        <form
          @submit.prevent="handleSubmit"
          class="flex flex-col justify-center px-6 py-8 mt-16 w-full bg-white rounded-md border border-solid shadow-sm max-md:px-5 max-md:mt-10"
        >
          <h1
            class="text-3xl font-semibold leading-9 text-center text-zinc-800"
          >
            Create new password
          </h1>
          <p class="mt-3 text-base leading-6 text-center text-neutral-700">
            Set your new password
          </p>
          <!-- Error Message Section -->
          <div
            v-if="errorMessage"
            role="alert"
            class="flex gap-2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
          >
            <div
              class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
            >
              <img
                src="../assets/shared/failure.svg"
                alt="Error Icon"
                class="w-4 h-4"
              />
            </div>
            <p>{{ errorMessage }}</p>
          </div>
          <!-- Error Message Section -->
          <BaseLabel
            forAttr="newPassword"
            class="mt-6 text-sm font-medium leading-5 text-zinc-800 text-left"
            text="Set-up Password"
          />
          <div class="mt-6 text-left">
            <div class="relative">
              <BaseInput
                :type="showNewPassword ? 'text' : 'password'"
                id="password"
                v-model="newPassword"
                className="px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full"
                required
              />
              <button
                type="button"
                @click="toggleNewPasswordVisibility"
                class="absolute right-3 top-1/2 transform -translate-y-1/2"
              >
                <i
                  :class="{
                    'fas fa-eye': showNewPassword,
                    'fas fa-eye-slash': !showNewPassword,
                  }"
                  :title="showNewPassword ? 'Hide password' : 'Show password'"
                  class="w-5 h-5 cursor-pointer"
                ></i>
              </button>
            </div>
          </div>
          <PasswordStrength :password="newPassword" />
          <BaseLabel
            forAttr="confirmPassword"
            class="mt-6 text-sm font-medium leading-5 text-zinc-800 text-left"
            text="Confirm Password"
          />
          <div class="relative">
            <BaseInput
              :type="showConfirmPassword ? 'text' : 'password'"
              id="confirmPassword"
              v-model="confirmPassword"
              className="px-3.5 py-2.5 mt-1 text-sm leading-5 text-gray-900 whitespace-nowrap bg-white rounded-md border border-gray-300 border-solid shadow-sm max-md:pr-5 w-full"
              required
            />
            <button
              type="button"
              @click="toggleConfirmPasswordVisibility"
              class="absolute right-3 top-1/2 transform -translate-y-1/2"
            >
              <i
                :class="{
                  'fas fa-eye': showConfirmPassword,
                  'fas fa-eye-slash': !showConfirmPassword,
                }"
                :title="showConfirmPassword ? 'Hide password' : 'Show password'"
                class="w-5 h-5 cursor-pointer"
              ></i>
            </button>
          </div>
          <BaseButton
            type="submit"
            className="px-4 py-2.5 mt-6 text-base font-semibold leading-6 text-white bg-violet-600 rounded-md shadow-sm max-md:px-5"
            :disabled="disabled"
          >
            Change Password
          </BaseButton>
        </form>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import PasswordStrength from "@/components/PasswordStrength.vue";
import BaseLabel from "@/components/shared/BaseLabel.vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import BaseInput from "@/components/shared/BaseInput.vue";
import axios, { AxiosError } from "axios";
import { ErrorResponse } from "@/types/interfaces";
import { useRoute } from "vue-router";
import router from "@/router";

export default defineComponent({
  name: "PasswordResetForm",
  components: {
    PasswordStrength,
    BaseLabel,
    BaseButton,
    BaseInput,
  },
  setup() {
    const newPassword = ref("");
    const confirmPassword = ref("");
    const errorMessage = ref<string | null>(null);
    const route = useRoute();
    const resetPasswordToken = route.query.reset_password_token as string;
    const showConfirmPassword = ref(false);
    const showNewPassword = ref(false);
    const disabled = ref(false);

    const handleSubmit = async () => {
      disabled.value = true;
      let validationFailure = validate();
      if (validationFailure.length > 0) {
        errorMessage.value = validationFailure;
        disabled.value = false;
      } else {
        try {
          const { url, data, config } = getResetPasswordRequestParams(
            newPassword.value,
            confirmPassword.value,
            resetPasswordToken
          );
          const response = await axios.put(url, data, config);

          if (response.status === 200) {
            router.push({ name: "SignIn" });
          } else {
            errorMessage.value = "Something went wrong, please try again.";
          }
          disabled.value = false;
        } catch (error) {
          handleError(error as AxiosError<ErrorResponse>);
        } finally {
          disabled.value = false;
        }
      }
    };

    const getResetPasswordRequestParams = (
      password: string,
      passwordConfirmation: string,
      resetPasswordToken: string
    ) => {
      return {
        url: "https://staging-api.slashexperts.com/password",
        data: {
          user: {
            password: password,
            password_confirmation: passwordConfirmation,
            reset_password_token: resetPasswordToken,
          },
        },
        config: {
          headers: {
            "Content-Type": "application/json",
          },
        },
      };
    };

    const validate = () => {
      if (!isPasswordValid.value) {
        return "Password must be 8 characters long, with at least one uppercase and lowercase letter";
      }

      if (newPassword.value != confirmPassword.value) {
        return "New password and confirm password do not match";
      }

      return "";
    };

    const handleError = (error: AxiosError<ErrorResponse>) => {
      if (error.response) {
        const errorData = error.response.data;
        if (typeof errorData === "string") {
          errorMessage.value = errorData;
        } else if (Array.isArray(errorData.errors)) {
          errorMessage.value = errorData.errors.join(", ");
        } else if (typeof errorData.errors === "string") {
          errorMessage.value = errorData.errors;
        } else {
          errorMessage.value = "An unexpected error occurred.";
        }
      } else {
        errorMessage.value = "An unexpected error occurred.";
      }
    };

    const toggleConfirmPasswordVisibility = () => {
      showConfirmPassword.value = !showConfirmPassword.value;
    };

    const toggleNewPasswordVisibility = () => {
      showNewPassword.value = !showNewPassword.value;
    };

    const isPasswordValid = computed(() => {
      const hasUpperCase = /[A-Z]/.test(newPassword.value);
      const hasLowerCase = /[a-z]/.test(newPassword.value);
      const hasMinLength = newPassword.value.length >= 8;
      return hasUpperCase && hasLowerCase && hasMinLength;
    });

    return {
      newPassword,
      confirmPassword,
      handleSubmit,
      errorMessage,
      validate,
      isPasswordValid,
      resetPasswordToken,
      toggleNewPasswordVisibility,
      toggleConfirmPasswordVisibility,
      showNewPassword,
      showConfirmPassword,
      disabled,
    };
  },
});
</script>
