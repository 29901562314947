import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b0b80884"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50"
}
const _hoisted_2 = { class: "bg-white p-6 rounded-lg shadow-lg w-80" }
const _hoisted_3 = { class: "mb-4" }
const _hoisted_4 = { class: "mb-4" }
const _hoisted_5 = { class: "bg-gray-300 rounded-full h-4" }
const _hoisted_6 = { class: "text-sm text-gray-600 mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.message), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "bg-primary-purple h-4 rounded-full",
                style: _normalizeStyle({ width: _ctx.progress + '%' })
              }, null, 4)
            ]),
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.progress) + "% complete", 1)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}