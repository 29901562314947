import { defineStore } from "pinia";
import { ProfileInformation } from "../types/interfaces";
import { Card } from "@/types/onboarding/types";
interface AuthState {
  token: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  profileInformation: ProfileInformation;
  softwareExpertise: string[];
  userCreatedSoftwareCards: Card[];
  programsLinked: Card[];
}

export const useAuthStore = defineStore("auth", {
  state: (): AuthState => ({
    token: null,
    email: null,
    firstName: null,
    lastName: null,
    profileInformation: {
      linkedinUrl: "",
      country: "",
      state: "",
      industry: "",
      profilePicture: "",
      about: "",
    },
    softwareExpertise: [],
    userCreatedSoftwareCards: [],
    programsLinked: [],
  }),
  actions: {
    setToken(newToken: string) {
      this.token = newToken;
    },
    setEmail(newEmail: string) {
      this.email = newEmail;
    },
    setFirstName(newFirstName: string) {
      this.firstName = newFirstName;
    },
    setLastName(newLastName: string) {
      this.lastName = newLastName;
    },
    setProfileInformation(newProfileInformation: Partial<ProfileInformation>) {
      this.profileInformation = {
        ...this.profileInformation,
        ...newProfileInformation,
      };
    },
    setSoftwareExpertise(newSoftwareExpertise: string[]) {
      this.softwareExpertise = newSoftwareExpertise;
    },
    setUserCreatedSoftwareCards(newUserCreatedSoftwareCards: Card[]) {
      this.userCreatedSoftwareCards = newUserCreatedSoftwareCards;
    },
    setProgramsLinked(newProgramsLinked: Card[]) {
      this.programsLinked = newProgramsLinked;
    },
  },
  persist: true,
});
