export const DASHBOARD_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M4 4V3H3V4H4ZM20 4H21V3H20V4ZM6.29289 11.2929C5.90237 11.6834 5.90237 12.3166 6.29289 12.7071C6.68342 13.0976 7.31658 13.0976 7.70711 12.7071L6.29289 11.2929ZM10 9L10.7071 8.29289C10.3166 7.90237 9.68342 7.90237 9.29289 8.29289L10 9ZM13 12L12.2929 12.7071C12.6834 13.0976 13.3166 13.0976 13.7071 12.7071L13 12ZM17.7071 8.70711C18.0976 8.31658 18.0976 7.68342 17.7071 7.29289C17.3166 6.90237 16.6834 6.90237 16.2929 7.29289L17.7071 8.70711ZM7.29289 20.2929C6.90237 20.6834 6.90237 21.3166 7.29289 21.7071C7.68342 22.0976 8.31658 22.0976 8.70711 21.7071L7.29289 20.2929ZM12 17L12.7071 16.2929C12.3166 15.9024 11.6834 15.9024 11.2929 16.2929L12 17ZM15.2929 21.7071C15.6834 22.0976 16.3166 22.0976 16.7071 21.7071C17.0976 21.3166 17.0976 20.6834 16.7071 20.2929L15.2929 21.7071ZM3 3C2.44772 3 2 3.44772 2 4C2 4.55228 2.44772 5 3 5V3ZM21 5C21.5523 5 22 4.55228 22 4C22 3.44772 21.5523 3 21 3V5ZM4 5H20V3H4V5ZM19 4V16H21V4H19ZM19 16H5V18H19V16ZM5 16V4H3V16H5ZM5 16H3C3 17.1046 3.89543 18 5 18V16ZM19 16V18C20.1046 18 21 17.1046 21 16H19ZM7.70711 12.7071L10.7071 9.70711L9.29289 8.29289L6.29289 11.2929L7.70711 12.7071ZM9.29289 9.70711L12.2929 12.7071L13.7071 11.2929L10.7071 8.29289L9.29289 9.70711ZM13.7071 12.7071L17.7071 8.70711L16.2929 7.29289L12.2929 11.2929L13.7071 12.7071ZM8.70711 21.7071L12.7071 17.7071L11.2929 16.2929L7.29289 20.2929L8.70711 21.7071ZM11.2929 17.7071L15.2929 21.7071L16.7071 20.2929L12.7071 16.2929L11.2929 17.7071ZM3 5H21V3H3V5Z"
      fill="currentColor"
    />
  </svg>
  `;

export const EXPERTISE_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
  >
    <path
      d="M9 17V7M9 17C9 18.1046 8.10457 19 7 19H5C3.89543 19 3 18.1046 3 17V7C3 5.89543 3.89543 5 5 5H7C8.10457 5 9 5.89543 9 7M9 17C9 18.1046 9.89543 19 11 19H13C14.1046 19 15 18.1046 15 17M9 7C9 5.89543 9.89543 5 11 5H13C14.1046 5 15 5.89543 15 7M15 17V7M15 17C15 18.1046 15.8954 19 17 19H19C20.1046 19 21 18.1046 21 17V7C21 5.89543 20.1046 5 19 5H17C15.8954 5 15 5.89543 15 7"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`;

export const EARNINGS_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M13.8434 9.65538C14.2053 10.0725 14.8369 10.1173 15.254 9.7553C15.6712 9.39335 15.7159 8.76176 15.354 8.34462L13.8434 9.65538ZM10.1567 14.3446C9.79471 13.9275 9.16313 13.8827 8.74599 14.2447C8.32885 14.6067 8.28411 15.2382 8.64607 15.6554L10.1567 14.3446ZM13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7H13ZM11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17L11 17ZM20 12C20 16.4183 16.4183 20 12 20V22C17.5228 22 22 17.5228 22 12H20ZM12 20C7.58172 20 4 16.4183 4 12H2C2 17.5228 6.47715 22 12 22V20ZM4 12C4 7.58172 7.58172 4 12 4V2C6.47715 2 2 6.47715 2 12H4ZM12 4C16.4183 4 20 7.58172 20 12H22C22 6.47715 17.5228 2 12 2V4ZM12 11C11.3415 11 10.7905 10.8202 10.4334 10.5822C10.0693 10.3394 10 10.1139 10 10H8C8 10.9907 8.6023 11.7651 9.32398 12.2463C10.0526 12.732 11.0017 13 12 13V11ZM10 10C10 9.8861 10.0693 9.66058 10.4334 9.41784C10.7905 9.17976 11.3415 9 12 9V7C11.0017 7 10.0526 7.26796 9.32398 7.75374C8.6023 8.23485 8 9.00933 8 10H10ZM12 9C12.9038 9 13.563 9.33231 13.8434 9.65538L15.354 8.34462C14.5969 7.47209 13.3171 7 12 7V9ZM12 13C12.6585 13 13.2095 13.1798 13.5666 13.4178C13.9308 13.6606 14 13.8861 14 14H16C16 13.0093 15.3977 12.2348 14.676 11.7537C13.9474 11.268 12.9983 11 12 11V13ZM11 7V8H13V7H11ZM11 16L11 17L13 17L13 16L11 16ZM12 15C11.0962 15 10.437 14.6677 10.1567 14.3446L8.64607 15.6554C9.40317 16.5279 10.683 17 12 17L12 15ZM14 14C14 14.1139 13.9308 14.3394 13.5666 14.5822C13.2095 14.8202 12.6586 15 12 15V17C12.9983 17 13.9474 16.732 14.676 16.2463C15.3977 15.7651 16 14.9907 16 14H14ZM11 8L11 16L13 16L13 8L11 8Z"
      fill="currentColor"
    />
  </svg>
`;

export const EARNINGS_ICON_CALIBRI = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <circle
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      stroke-width="1.5"
      fill="none"
    />
    <text
      x="12"
      y="12"
      text-anchor="middle"
      font-size="12"
      font-family="Calibri"
      fill="currentColor"
      dominant-baseline="central"
    >
      $
    </text>
  </svg>
`;

export const PROGRAMS_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M17 14V20M14 17H20M6 10H8C9.10457 10 10 9.10457 10 8V6C10 4.89543 9.10457 4 8 4H6C4.89543 4 4 4.89543 4 6V8C4 9.10457 4.89543 10 6 10ZM16 10H18C19.1046 10 20 9.10457 20 8V6C20 4.89543 19.1046 4 18 4H16C14.8954 4 14 4.89543 14 6V8C14 9.10457 14.8954 10 16 10ZM6 20H8C9.10457 20 10 19.1046 10 18V16C10 14.8954 9.10457 14 8 14H6C4.89543 14 4 14.8954 4 16V18C4 19.1046 4.89543 20 6 20Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`;

export const PHONE_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.22792 3.68377L8.27924 4L8.27924 4L9.22792 3.68377ZM10.7257 8.17721L9.77705 8.49344L10.7257 8.17721ZM10.2243 9.38787L10.6715 10.2823L10.6715 10.2823L10.2243 9.38787ZM7.96701 10.5165L7.51979 9.62207C7.03975 9.86209 6.83479 10.4383 7.05538 10.9275L7.96701 10.5165ZM13.4835 16.033L13.0725 16.9446C13.5618 17.1652 14.1379 16.9602 14.3779 16.4802L13.4835 16.033ZM14.6121 13.7757L13.7177 13.3285L14.6121 13.7757ZM15.8228 13.2743L15.5066 14.2229L15.5066 14.2229L15.8228 13.2743ZM20.3162 14.7721L20.6325 13.8234L20.3162 14.7721ZM5 2C3.34315 2 2 3.34315 2 5H4C4 4.44772 4.44772 4 5 4V2ZM8.27924 2H5V4H8.27924V2ZM10.1766 3.36755C9.90438 2.55086 9.1401 2 8.27924 2V4L8.27924 4L10.1766 3.36755ZM11.6744 7.86098L10.1766 3.36754L8.27924 4L9.77705 8.49344L11.6744 7.86098ZM10.6715 10.2823C11.5617 9.83718 11.9892 8.80521 11.6744 7.86098L9.77705 8.49344L9.77705 8.49344L10.6715 10.2823ZM8.41422 11.4109L10.6715 10.2823L9.77705 8.49344L7.51979 9.62207L8.41422 11.4109ZM13.8945 15.1214C11.6721 14.1194 9.88063 12.3279 8.87863 10.1055L7.05538 10.9275C8.25786 13.5946 10.4054 15.7421 13.0725 16.9446L13.8945 15.1214ZM13.7177 13.3285L12.5891 15.5858L14.3779 16.4802L15.5066 14.2229L13.7177 13.3285ZM16.139 12.3256C15.1948 12.0108 14.1628 12.4383 13.7177 13.3285L15.5066 14.2229L15.5066 14.2229L16.139 12.3256ZM20.6325 13.8234L16.139 12.3256L15.5066 14.2229L20 15.7208L20.6325 13.8234ZM22 15.7208C22 14.8599 21.4491 14.0956 20.6325 13.8234L20 15.7208H22ZM22 19V15.7208H20V19H22ZM19 22C20.6569 22 22 20.6569 22 19H20C20 19.5523 19.5523 20 19 20V22ZM18 22H19V20H18V22ZM2 6C2 14.8366 9.16344 22 18 22V20C10.268 20 4 13.732 4 6H2ZM2 5V6H4V5H2Z"
      fill="currentColor"
    />
  </svg>
`;

export const APPROVED = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    class="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.01346 2.76406C5.52792 2.72301 6.01631 2.52071 6.40911 2.18597C7.32576 1.40481 8.67395 1.40481 9.59059 2.18597C9.9834 2.52071 10.4718 2.72301 10.9862 2.76406C12.1868 2.85987 13.1401 3.81318 13.2359 5.01371C13.2769 5.52816 13.4792 6.01655 13.814 6.40936C14.5951 7.326 14.5951 8.67419 13.814 9.59084C13.4792 9.98364 13.2769 10.472 13.2359 10.9865C13.1401 12.187 12.1868 13.1403 10.9862 13.2361C10.4718 13.2772 9.9834 13.4795 9.59059 13.8142C8.67395 14.5954 7.32576 14.5954 6.40911 13.8142C6.01631 13.4795 5.52792 13.2772 5.01346 13.2361C3.81294 13.1403 2.85962 12.187 2.76382 10.9865C2.72276 10.472 2.52047 9.98364 2.18572 9.59084C1.40456 8.67419 1.40456 7.326 2.18572 6.40936C2.52047 6.01655 2.72276 5.52816 2.76382 5.01371C2.85962 3.81318 3.81294 2.85987 5.01346 2.76406ZM10.9655 6.96578C11.278 6.65336 11.278 6.14683 10.9655 5.83441C10.6531 5.52199 10.1466 5.52199 9.83417 5.83441L7.19985 8.46873L6.16554 7.43441C5.85312 7.12199 5.34659 7.12199 5.03417 7.43441C4.72175 7.74683 4.72175 8.25336 5.03417 8.56578L6.63417 10.1658C6.94659 10.4782 7.45312 10.4782 7.76554 10.1658L10.9655 6.96578Z"
      fill="currentColor"
    />
  </svg>
`;

export const CIRCLE_PLUS = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    class="currentColor"
  >
    <path
      d="M12 9V12M12 12V15M12 12H15M12 12H9M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`;

export const CHECK_MARK = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    class="size-6"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="m4.5 12.75 6 6 9-13.5"
    />
  </svg>
`;

export const VIDEO_CAMERA_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="h-20 w-20"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <path d="M23 7l-7 5 7 5V7z" />
    <rect
      x="1"
      y="5"
      width="15"
      height="14"
      rx="2"
      ry="2"
    />
  </svg>
`;

export const CIRCLE_EXCLAMATION = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="w-6 h-6 text-primary-purple"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
    <circle
      cx="12"
      cy="12"
      r="10"
      fill="none"
      stroke="currentColor"
    />
    <path d="M12 16v-4m0-4h.01m0 8h-.01" fill="currentColor" />
  </svg>
`;

export const CALENDAR_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M8 7V3M16 7V3M7 11H17M5 21H19C20.1046 21 21 20.1046 21 19V7C21 5.89543 20.1046 5 19 5H5C3.89543 5 3 5.89543 3 7V19C3 20.1046 3.89543 21 5 21Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`;

export const PEOPLE_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 4.35418C12.7329 3.52375 13.8053 3 15 3C17.2091 3 19 4.79086 19 7C19 9.20914 17.2091 11 15 11C13.8053 11 12.7329 10.4762 12 9.64582M15 21H3V20C3 16.6863 5.68629 14 9 14C12.3137 14 15 16.6863 15 20V21ZM15 21H21V20C21 16.6863 18.3137 14 15 14C13.9071 14 12.8825 14.2922 12 14.8027M13 7C13 9.20914 11.2091 11 9 11C6.79086 11 5 9.20914 5 7C5 4.79086 6.79086 3 9 3C11.2091 3 13 4.79086 13 7Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`;

export const CIRCLE_MINUS = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
  >
    <path
      d="M15 12H9M21 12C21 13.1819 20.7672 14.3522 20.3149 15.4442C19.8626 16.5361 19.1997 17.5282 18.364 18.364C17.5282 19.1997 16.5361 19.8626 15.4442 20.3149C14.3522 20.7672 13.1819 21 12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`;

export const NOTE_ICON = `
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
  >
    <path
      d="M15.75 17.25V20.625C15.75 21.246 15.246 21.75 14.625 21.75H4.875C4.57663 21.75 4.29048 21.6315 4.0795 21.4205C3.86853 21.2095 3.75 20.9234 3.75 20.625V7.875C3.75 7.254 4.254 6.75 4.875 6.75H6.75C7.25257 6.74966 7.7543 6.79114 8.25 6.874M15.75 17.25H19.125C19.746 17.25 20.25 16.746 20.25 16.125V11.25C20.25 6.79 17.007 3.089 12.75 2.374C12.2543 2.29114 11.7526 2.24966 11.25 2.25H9.375C8.754 2.25 8.25 2.754 8.25 3.375V6.874M15.75 17.25H9.375C9.07663 17.25 8.79048 17.1315 8.5795 16.9205C8.36853 16.7095 8.25 16.4234 8.25 16.125V6.874M20.25 13.5V11.625C20.25 10.7299 19.8944 9.87145 19.2615 9.23852C18.6286 8.60558 17.7701 8.25 16.875 8.25H15.375C15.0766 8.25 14.7905 8.13148 14.5795 7.9205C14.3685 7.70952 14.25 7.42337 14.25 7.125V5.625C14.25 5.18179 14.1627 4.74292 13.9931 4.33345C13.8235 3.92397 13.5749 3.55191 13.2615 3.23852C12.9481 2.92512 12.576 2.67652 12.1666 2.50691C11.7571 2.3373 11.3182 2.25 10.875 2.25H9.75"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`;
