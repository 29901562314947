<template>
  <div class="flex flex-wrap gap-5 flex-1">
    <article class="flex flex-col flex-1 max-md:ml-0 max-md:w-full">
      <div
        class="flex flex-col mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid max-md:mt-5 h-full"
      >
        <div class="flex gap-5 items-center p-6 w-full max-md:px-5">
          <div
            class="flex justify-center items-center self-stretch px-3 my-auto w-12 h-12 bg-violet-600 rounded-md"
          >
            <div v-html="EARNINGS_ICON" class="text-white"></div>
          </div>
          <div
            class="flex flex-col flex-1 shrink self-stretch my-auto whitespace-nowrap basis-6"
          >
            <div
              class="text-sm font-medium leading-none text-gray-500 text-left"
            >
              Earnings
            </div>
            <div
              class="gap-2 self-start text-2xl mt-2 font-semibold leading-none text-zinc-800"
            >
              $71,897
            </div>
          </div>
        </div>
        <BaseButton
          type="button"
          class="flex flex-col justify-center items-start p-4 w-full text-sm font-medium leading-none text-indigo-600 bg-gray-50 text-left"
        >
          <span class="self-stretch">See Details</span>
        </BaseButton>
      </div>
    </article>
    <article class="flex flex-col flex-1 max-md:ml-0 max-md:w-full">
      <div
        class="flex flex-col mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid max-md:mt-5 h-full"
      >
        <div class="flex gap-5 items-center p-6 w-full max-md:px-5">
          <div
            class="flex justify-center items-center self-stretch px-3 my-auto w-12 h-12 bg-violet-600 rounded-md"
          >
            <div v-html="PROGRAMS_ICON" class="text-white"></div>
          </div>
          <div class="flex flex-col flex-1 shrink self-stretch my-auto basis-6">
            <div
              class="text-sm font-medium leading-none text-gray-500 text-left"
            >
              Total Programs
            </div>
            <div
              class="gap-2 self-start text-2xl mt-2 font-semibold leading-none whitespace-nowrap text-zinc-800"
            >
              {{ totalPrograms }}
            </div>
          </div>
        </div>
      </div>
    </article>
    <article class="flex flex-col flex-1 max-md:ml-0 max-md:w-full">
      <div
        class="flex flex-col mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid max-md:mt-5 h-full"
      >
        <div class="flex gap-5 items-center p-6 w-full max-md:px-5">
          <div
            class="flex justify-center items-center self-stretch px-3 my-auto w-12 h-12 bg-violet-600 rounded-md"
          >
            <div v-html="PHONE_ICON" class="text-white"></div>
          </div>
          <div class="flex flex-col flex-1 shrink self-stretch my-auto basis-6">
            <div
              class="text-sm font-medium leading-none text-gray-500 text-left"
            >
              Upcoming Meetings
            </div>
            <div
              class="gap-2 self-start text-2xl mt-2 font-semibold leading-none whitespace-nowrap text-zinc-800"
            >
              {{ upcomingMeetings }}
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script lang="ts">
import {
  EARNINGS_ICON,
  PROGRAMS_ICON,
  PHONE_ICON,
} from "@/assets/svg/expert-overview/svgConstants";
import BaseButton from "../shared/BaseButton.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "InformationBlocks",
  props: {
    totalPrograms: String,
    upcomingMeetings: String,
  },
  components: {
    BaseButton,
  },
  setup() {
    return {
      PHONE_ICON,
      PROGRAMS_ICON,
      EARNINGS_ICON,
    };
  },
});
</script>
